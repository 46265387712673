import { Dispatch } from "react";
import { SequenceItemInput, SequenceItemInputFields } from "../../SequenceItemSelector";
import { SequenceItemWithSequence } from "../../../types";
import fuzzysort from "fuzzysort";
import moment from "moment";
import { formatSequenceItemWithSequenceName, splitSequenceItemName, expectUnreachable } from "./utils";
import { SearchState, SearchStateAction } from "./searchState";
import { searchOptionsLimit } from "./constants";

export const sequenceItemSearch = ({
    sequenceItems,
    search,
    dispatch,
}: {
    sequenceItems?: SequenceItemWithSequence[];
    search: SearchState;
    dispatch: Dispatch<SearchStateAction>;
}) => {
    const allSequenceItems = sequenceItems || [];
    const { selected, input } = search;

    const handleSequenceItemChanged = (_sequenceItemInput: SequenceItemInput, field: SequenceItemInputFields) => {
        const softMatchSequenceItem = (searchInput: string) => {
            const [sqItemInput, sqInput] = splitSequenceItemName(searchInput);

            return allSequenceItems.find(
                (sqi) =>
                    sqi.name.toLowerCase() === sqItemInput.toLowerCase() &&
                    sqi.sequence.displayName.toLowerCase() === sqInput?.toLowerCase()
            );
        };

        switch (field) {
            case "sequenceItemId": {
                const sequenceItemMatch = softMatchSequenceItem(_sequenceItemInput.sequenceItemId);
                if (sequenceItemMatch) {
                    dispatch({ type: "setSequenceItem", sequenceItem: sequenceItemMatch });
                } else {
                    dispatch({ type: "setSequenceItemInput", input: _sequenceItemInput.sequenceItemId });
                }
                break;
            }
            case "secondSequenceItemId": {
                if (_sequenceItemInput.contractType === "SinglePeriod") {
                    console.warn("Second sequence item input changed, when SinglePeriod was set");
                    break; // TODO: Throw (once error boundaries are present)
                }
                const secondSequenceItemMatch = softMatchSequenceItem(_sequenceItemInput.secondSequenceItemId);
                if (secondSequenceItemMatch) {
                    dispatch({ type: "setSecondSequenceItem", sequenceItem: secondSequenceItemMatch });
                } else {
                    dispatch({ type: "setSecondSequenceItemInput", input: _sequenceItemInput.secondSequenceItemId });
                }
                break;
            }
            case "contractType": {
                dispatch({ type: "setContractType", contractType: _sequenceItemInput.contractType });
                break;
            }
            default:
                expectUnreachable(field);
        }
    };

    const handleSequenceItemBlur = (
        _sequenceItemInput: SequenceItemInput,
        field: Exclude<SequenceItemInputFields, "contractType"> | "sequenceItems"
    ) => {
        const hardMatchSequenceItem = (searchInput: string) => {
            const [sqItemInput, sqInput] = splitSequenceItemName(searchInput);

            return (
                allSequenceItems.find(
                    (sqi) =>
                        sqi.name.toLowerCase() === sqItemInput.toLowerCase() &&
                        sqi.sequence.displayName.toLowerCase() === sqInput?.toLowerCase()
                ) ||
                allSequenceItems.find((sqi) => /^\d+$/.test(sqItemInput) && parseInt(sqItemInput) === sqi.id) ||
                allSequenceItems
                    .filter((sqi) => !sqi.sequence.displayName?.includes("Custom Spreads"))
                    .find((sqi) => sqi.name.toLowerCase() === sqItemInput.toLowerCase()) ||
                allSequenceItems.find((sqi) => sqi.name.toLowerCase() === sqItemInput.toLowerCase())
            );
        };

        switch (field) {
            case "sequenceItemId": {
                const sequenceItemMatch = hardMatchSequenceItem(_sequenceItemInput.sequenceItemId);

                if (sequenceItemMatch) {
                    dispatch({ type: "setSequenceItem", sequenceItem: sequenceItemMatch });
                } else {
                    dispatch({ type: "setSequenceItemInvalid", input: _sequenceItemInput.sequenceItemId });
                }
                break;
            }
            case "secondSequenceItemId": {
                if (_sequenceItemInput.contractType === "SinglePeriod") {
                    console.warn("Second sequence item input changed, when SinglePeriod was set");
                    break; // TODO: Throw (once error boundaries are present)
                }
                const secondSequenceItemMatch = hardMatchSequenceItem(_sequenceItemInput.secondSequenceItemId);

                if (secondSequenceItemMatch) {
                    dispatch({ type: "setSecondSequenceItem", sequenceItem: secondSequenceItemMatch });
                } else {
                    dispatch({ type: "setSecondSequenceItemInvalid", input: _sequenceItemInput.secondSequenceItemId });
                }
                break;
            }
            case "sequenceItems": {
                if (_sequenceItemInput.contractType === "SinglePeriod") {
                    console.warn("Second sequence item input changed, when SinglePeriod was set");
                    break; // TODO: Throw (once error boundaries are present)
                }
                const sequenceItemMatch = hardMatchSequenceItem(_sequenceItemInput.sequenceItemId);
                const secondSequenceItemMatch = hardMatchSequenceItem(_sequenceItemInput.secondSequenceItemId);

                dispatch({
                    type: "compound",
                    actions: [
                        sequenceItemMatch
                            ? { type: "setSequenceItem", sequenceItem: sequenceItemMatch }
                            : {
                                  type: "setSequenceItemInvalid",
                                  input: _sequenceItemInput.sequenceItemId,
                              },
                        secondSequenceItemMatch
                            ? ({
                                  type: "setSecondSequenceItem",
                                  sequenceItem: secondSequenceItemMatch,
                              } as const)
                            : ({
                                  type: "setSecondSequenceItemInvalid",
                                  input: _sequenceItemInput.secondSequenceItemId,
                              } as const),
                    ],
                });
                break;
            }
            default:
                expectUnreachable(field);
        }
    };

    const searchSequenceItems = (searchInput: string, isFirstItem: boolean) => {
        const isSequenceItemValidOrdering = (sqi: SequenceItemWithSequence) =>
            isFirstItem
                ? selected.secondSequenceItem &&
                  selected.secondSequenceItem.sequence.id === sqi.sequence.id &&
                  selected.secondSequenceItem.id > sqi.id
                : selected.sequenceItem &&
                  selected.sequenceItem.sequence.id === sqi.sequence.id &&
                  selected.sequenceItem.id < sqi.id;

        const otherSequenceItem = isFirstItem ? selected.secondSequenceItem : selected.sequenceItem;

        const defaultItemOrder = (sqis: SequenceItemWithSequence[]): SequenceItemWithSequence[] => {
            if (otherSequenceItem) {
                return [
                    ...sqis.filter(
                        (sqi) => sqi.sequence.id === otherSequenceItem.sequence.id && isSequenceItemValidOrdering(sqi)
                    ),
                    ...sqis.filter(
                        (sqi) => sqi.sequence.id === otherSequenceItem.sequence.id && !isSequenceItemValidOrdering(sqi)
                    ),
                    ...sqis.filter((sqi) => sqi.sequence.id != otherSequenceItem.sequence.id),
                ];
            }

            if (selected.sequence) {
                return [
                    ...sqis.filter((sqi) => sqi.sequence.id === selected.sequence?.id),
                    ...sqis.filter((sqi) => sqi.sequence.id !== selected.sequence?.id),
                ];
            }

            return sqis;        // Sequence items are presorted after fetching from RefData in `refdata/utils.ts`. TODO: Would it make more sense here?
        };

        if (searchInput === "") {
            return defaultItemOrder(allSequenceItems)
                .slice(0, searchOptionsLimit)
                .map(formatSequenceItemWithSequenceName);
        }

        const [sqItemInput, sqInput] = splitSequenceItemName(searchInput);

        const sorted = fuzzysort.go(sqItemInput, allSequenceItems, {
            keys: ["name"],
            limit: searchOptionsLimit,
            all: true,
            threshold: -1_000_000,
            scoreFn: (a) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const sqi: SequenceItemWithSequence = (a as any).obj;

                if (sqi.name.toLowerCase() === sqItemInput && sqi.sequence.displayName.toLowerCase() === sqInput) {
                    return 1_000_000;
                }

                if (/^\d+$/.test(sqItemInput) && parseInt(sqItemInput) === sqi.id) {
                    return 900_000;
                }
                const [nameMatch] = a;
                if (nameMatch === null) {
                    return sqi.sequence.id !== selected.sequence?.id ? (sqItemInput ? -1_000_001 : -500_000) : -100_000;
                }

                const relativeTime = Math.abs(moment().diff(sqi.periodStart));

                const hasInvalidId = !isSequenceItemValidOrdering(sqi);

                const score =
                    nameMatch.score +
                    (sqi.sequence.id !== selected.sequence?.id ? -1000 : 0) + // Deprioritise other sequences when sequence is set
                    (sqi.sequence.displayName?.includes("Custom Spreads") ? -1000 : 0) + // Deprioritise Custom Spreads
                    (relativeTime !== 0 ? 1 / relativeTime : 0) +
                    (hasInvalidId ? -1000 : 0); //Deprioritise sequences that have ids lower or higher (depending on input) than the already set item;
                return score;
            },
        });
        return sorted.map((result) => result.obj).map(formatSequenceItemWithSequenceName);
    };

    return {
        handleSequenceItemBlur,
        handleSequenceItemChanged,
        sequenceItemSearchOptions: searchSequenceItems(input.sequenceItem.sequenceItemId.toLowerCase(), true),
        secondSequenceItemSearchOptions:
            input.sequenceItem.contractType !== "SinglePeriod"
                ? searchSequenceItems(input.sequenceItem.secondSequenceItemId.toLowerCase(), false)
                : [],
    };
};
