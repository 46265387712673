import React from "react";
import {
    GridToolbarContainer,
    GridPreferencePanelsValue,
    gridPreferencePanelStateSelector,
    useGridSelector,
    gridFilterActiveItemsSelector,
    useGridApiContext,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import FilterAltSharpIcon from "@mui/icons-material/FilterAltSharp";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import SimCardDownloadSharpIcon from "@mui/icons-material/SimCardDownloadSharp";
import TableChartSharpIcon from "@mui/icons-material/TableChartSharp";
import Badge from "@mui/material/Badge";
import { Tooltip } from "../Tooltip";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

interface Props {
    isOwnSet: boolean;
    onIsOwnChange: (isOwnSet: boolean) => void;
    onCopy: () => void;
    onDownload: () => void;
    copyDisabled?: boolean;
}

export const DataGridToolbar: React.FC<Props> = ({
    isOwnSet,
    onIsOwnChange,
    onCopy,
    onDownload,
    copyDisabled = false,
}) => {
    const apiRef = useGridApiContext();
    const { open, openedPanelValue } = useGridSelector(apiRef, gridPreferencePanelStateSelector);
    const activeFilters = useGridSelector(apiRef, gridFilterActiveItemsSelector);

    const toggleGridPreferencePanel = (panel: GridPreferencePanelsValue) => {
        if (open && openedPanelValue === panel) {
            apiRef.current.hidePreferences();
        } else {
            apiRef.current.showPreferences(panel);
        }
    };

    return (
        <GridToolbarContainer>
            <Tooltip title="Filter data" arrow>
                <IconButton
                    onClick={() => toggleGridPreferencePanel(GridPreferencePanelsValue.filters)}
                    style={{ padding: 4 }}
                >
                    <Badge badgeContent={activeFilters.length} color="primary">
                        <FilterAltSharpIcon sx={{ fontSize: 28 }} />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Tooltip title="Show/hide columns" arrow>
                <IconButton
                    onClick={() => toggleGridPreferencePanel(GridPreferencePanelsValue.columns)}
                    style={{ padding: 4 }}
                >
                    <TableChartSharpIcon sx={{ fontSize: 28 }} />
                </IconButton>
            </Tooltip>
            <FormControlLabel
                control={<Switch checked={isOwnSet} onChange={(e) => onIsOwnChange(e.target.checked)} />}
                label="Own trades only"
                sx={{ marginLeft: 3 }}
            />
            <div style={{ flexGrow: 1 }} />
            <Tooltip title="Copy all to clipboard" arrow>
                <span>
                    <IconButton onClick={() => onCopy()} style={{ padding: 4 }} disabled={copyDisabled}>
                        <ContentCopySharpIcon sx={{ fontSize: 25 }} />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Download CSV" arrow>
                <span>
                    <IconButton onClick={() => onDownload()} style={{ padding: 4 }} disabled={copyDisabled}>
                        <SimCardDownloadSharpIcon sx={{ fontSize: 28 }} />
                    </IconButton>
                </span>
            </Tooltip>
        </GridToolbarContainer>
    );
};
