import React from "react";
import { ValueSelect, SelectValue } from "./Select";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";
import { Typography } from "./Typography";

const StyledTextField = styled(TextField)(({ theme }) => ({
    borderRadius: "0px",
    "& .MuiOutlinedInput-root": {
        backgroundColor: theme.palette.trayport.backgroundSearchElements,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.trayport.backgroundSearchElements,
        borderRadius: "0px",
    },
    "&.Mui-focused, &:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.trayport.hoverSearchElements,
        borderRadius: "0px",
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.trayport.hoverSearchElements,
    },
    "& .MuiInputBase-input": {
        paddingTop: "3px",
        paddingBottom: "3px",
    },
}));

export interface DateTimeRange {
    from: moment.Moment;
    until: moment.Moment;
}

interface NullableDateTimeRange {
    from: moment.Moment | null;
    until: moment.Moment | null;
}

type TimeResolveAction = (now: moment.Moment) => DateTimeRange;

interface DateTimePresets {
    Today: TimeResolveAction;
    Yesterday: TimeResolveAction;
    "This week": TimeResolveAction;
    "Last 7 days": TimeResolveAction;
    "Last week": TimeResolveAction;
    "This month": TimeResolveAction;
    "Last 30 days": TimeResolveAction;
    "Last month": TimeResolveAction;
    "This year": TimeResolveAction;
    "Last 365 days": TimeResolveAction;
    "Last year": TimeResolveAction;
}
export type DateTimePresetKeys = keyof DateTimePresets;

export const dateTimePresets: DateTimePresets = {
    Today: (now: moment.Moment) => ({
        from: now.clone().startOf("day"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    Yesterday: (now: moment.Moment) => ({
        from: now.clone().subtract(1, "day").startOf("day"),
        until: now.clone().startOf("day"),
    }),
    "This week": (now: moment.Moment) => ({
        from: now.clone().startOf("isoWeek"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    "Last 7 days": (now: moment.Moment) => ({
        from: now.clone().subtract(7, "day").startOf("day"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    "Last week": (now: moment.Moment) => ({
        from: now.clone().subtract(1, "week").startOf("isoWeek"),
        until: now.clone().startOf("isoWeek"),
    }),
    "This month": (now: moment.Moment) => ({
        from: now.clone().startOf("month"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    "Last 30 days": (now: moment.Moment) => ({
        from: now.clone().subtract(30, "day").startOf("day"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    "Last month": (now: moment.Moment) => ({
        from: now.clone().subtract(1, "month").startOf("month"),
        until: now.clone().startOf("month"),
    }),
    "This year": (now: moment.Moment) => ({
        from: now.clone().startOf("year"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    "Last 365 days": (now: moment.Moment) => ({
        from: now.clone().subtract(365, "day").startOf("day"),
        until: now.clone().add(1, "day").startOf("day"),
    }),
    "Last year": (now: moment.Moment) => ({
        from: now.clone().subtract(1, "year").startOf("year"),
        until: now.clone().startOf("year"),
    }),
};

export type DatePickerMode = "select" | "enter";

type PresetDateTimeRangePicked = {
    preset: DateTimePresetKeys;
    range: DateTimeRange;
};

type CustomDateTimeRangePicked = {
    preset: "Custom";
    range: NullableDateTimeRange;
    errors: {
        from?: string;
        until?: string;
    };
};

export type DateTimeRangePicked = PresetDateTimeRangePicked | CustomDateTimeRangePicked;

export const defaultDateTimeRangePicked: DateTimeRangePicked = {
    preset: "Today",
    range: dateTimePresets["Today"](moment()),
};

interface Props {
    value?: DateTimeRangePicked;
    onChange?: (dateTimeRangePicked: DateTimeRangePicked) => void;
}

export const DateRangePicker: React.FC<Props> = ({ value = defaultDateTimeRangePicked, onChange = () => null }) => {
    const maxSelectableTime = moment().add(1, "day").startOf("day");

    const sharedDateTimePickerProps = {
        inputFormat: "YYYY/MM/DD HH:mm",
        ampm: false,
        style: { padding: 3 },
    };

    const handlePresetChange = (event: SelectValue) => {
        if (event.value === "Custom") {
            return;
        }
        const preset = event.value as DateTimePresetKeys;
        onChange({
            preset: preset,
            range: dateTimePresets[preset](moment()),
        });
    };

    const getErrors = () => (value.preset === "Custom" ? value.errors : {});

    const handleDateRangeChange = (_value: moment.Moment | null, field: "from" | "until") => {
        if (!_value?.isValid()) {
            onChange({
                range: { ...value.range, [field]: _value },
                preset: "Custom",
                errors: { ...getErrors(), [field]: "Invalid date/time" },
            });
        } else if (_value === null) {
            onChange({
                range: { ...value.range, [field]: _value },
                preset: "Custom",
                errors: { ...getErrors(), [field]: undefined },
            });
        } else if (field === "from" && value.range.until !== null && _value > value.range.until) {
            onChange({
                range: { from: _value, until: null },
                preset: "Custom",
                errors: { ...getErrors(), [field]: undefined },
            });
        } else if (field === "until" && value.range.from !== null && _value < value.range.from) {
            onChange({
                range: { from: null, until: _value },
                preset: "Custom",
                errors: { ...getErrors(), [field]: undefined },
            });
        } else {
            onChange({
                range: { ...value.range, [field]: _value },
                preset: "Custom",
                errors: { ...getErrors(), [field]: undefined },
            });
        }
    };

    return (
        <Stack spacing={1}>
            <ValueSelect
                selectValues={[
                    ...Object.keys(dateTimePresets),
                    ...(value.preset === "Custom" ? ["Custom"] : []),
                ].map<SelectValue>((v) => ({
                    value: v,
                }))}
                value={value.preset}
                onValueChange={handlePresetChange}
                label="Preset"
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    From
                </Typography>
                <DateTimePicker
                    value={value.range.from}
                    onChange={(_value) => handleDateRangeChange(_value, "from")}
                    maxDateTime={value.range.until || maxSelectableTime}
                    renderInput={(props) => (
                        <StyledTextField
                            {...props}
                            helperText={getErrors().from ?? ""}
                            error={props.error || !!getErrors().from}
                        />
                    )}
                    {...sharedDateTimePickerProps}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    Until
                </Typography>
                <DateTimePicker
                    value={value.range.until}
                    onChange={(_value) => handleDateRangeChange(_value, "until")}
                    minDateTime={value.range.from}
                    maxDateTime={maxSelectableTime}
                    renderInput={(props) => (
                        <StyledTextField
                            {...props}
                            helperText={getErrors().until ?? ""}
                            error={props.error || !!getErrors().until}
                        />
                    )}
                    {...sharedDateTimePickerProps}
                />
            </div>
        </Stack>
    );
};
