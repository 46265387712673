import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem, { MenuItemProps } from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import MuiSelect, { SelectChangeEvent, SelectProps as MuiSelectProps } from "@mui/material/Select";
import { styled } from "@mui/material/styles";
import { Typography } from "./Typography";

const StyledSelect = styled(MuiSelect)(({ theme }) => ({
    backgroundColor: theme.palette.trayport.backgroundSearchElements,
    borderRadius: "0px",
    "&.Mui-focused, &:hover .MuiOutlinedInput-notchedOutline, &.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: theme.palette.trayport.hoverSearchElements,
    },
    "& .MuiInputBase-input": {
        paddingTop: "3px",
        paddingBottom: "3px",
    },
}));

interface SelectProps extends MuiSelectProps {
    label?: string;
}

export const Select: React.FC<SelectProps> = ({ children, label, ...props }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                {label}
            </Typography>
            <FormControl fullWidth>
                <StyledSelect {...props}>{children}</StyledSelect>
            </FormControl>
        </div>
    );
};

export interface SelectValue {
    value: string | number | readonly string[];
    label?: string;
}

interface ValueSelectProps<TValue extends SelectValue> extends SelectProps {
    selectValues: TValue[];
    onValueChange?: (selectedValue: TValue) => void;
    menuItemProps?: MenuItemProps;
}

export function ValueSelect<TValue extends SelectValue>({
    selectValues,
    menuItemProps,
    onValueChange,
    onChange,
    ...props
}: ValueSelectProps<TValue>): React.ReactElement {
    return (
        <Select
            {...props}
            onChange={(e, c) => {
                if (onChange) {
                    onChange(e, c);
                }
                if (onValueChange) {
                    const event = e as SelectChangeEvent;
                    onValueChange(selectValues.find((v) => v.value === event.target.value) ?? selectValues[0]);
                }
            }}
        >
            {selectValues.map((selectValue) => (
                <MenuItem {...menuItemProps} value={selectValue.value} key={selectValue.value.toString()}>
                    {selectValue.label || selectValue.value}
                </MenuItem>
            ))}
        </Select>
    );
}

const SelectWithLabel: React.FC<SelectProps> = ({ children, label, ...props }) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <FormControl fullWidth>
                <InputLabel variant="standard">{label}</InputLabel>
                <MuiSelect {...props}>{children}</MuiSelect>
            </FormControl>
        </div>
    );
};

export function ValueSelectWithLabel<TValue extends SelectValue>({
    label,
    selectValues,
    menuItemProps,
    onValueChange,
    onChange,
    ...props
}: ValueSelectProps<TValue>): React.ReactElement {
    return (
        <SelectWithLabel
            label={label}
            {...props}
            onChange={(e, c) => {
                if (onChange) {
                    onChange(e, c);
                }
                if (onValueChange) {
                    const event = e as SelectChangeEvent;
                    onValueChange(selectValues.find((v) => v.value === event.target.value) ?? selectValues[0]);
                }
            }}
        >
            {selectValues.map((selectValue) => (
                <MenuItem {...menuItemProps} value={selectValue.value} key={selectValue.value.toString()}>
                    {selectValue.label || selectValue.value}
                </MenuItem>
            ))}
        </SelectWithLabel>
    );
}
