import React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Typography } from "../../Components";
import { Grid } from "@mui/material";

const ErrorBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: "100vh",
    padding: theme.spacing(1),
    backgroundColor: theme.palette.trayport.backgroundThemeColor,
}));

const ErrorTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.text.primary,
}));

const ErrorPage: React.FC = () => {
    return (
        <Box sx={{ display: "flex" }}>
            <ErrorBox>
                <Grid
                    container
                    sx={{ width: "100%", height: "100%" }}
                    alignContent="center"
                    justifyContent="center"
                    direction="column"
                >
                    <ErrorTypography variant="h4" textAlign="center">
                        An error occured
                    </ErrorTypography>
                    <ErrorTypography variant="body1" textAlign="center">
                        If the issue persists, please contact Trayport Support
                    </ErrorTypography>
                </Grid>
            </ErrorBox>
        </Box>
    );
};

export default ErrorPage;
