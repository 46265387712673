import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import { DateRangePicker, DateTimeRange, DateTimeRangePicked, defaultDateTimeRangePicked } from "../DateRangePicker";
import { Drawer } from "../Drawer";
import { Button, CancelButton } from "../Button";
import { drawerWidth } from "../constants";
import IconButton from "@mui/material/IconButton";
import { useInstrumentSearch, InstrumentSearch } from "./instrumentSearch";
import { useKeyboardListener } from "../../Hooks/keyboardListener";
import { SearchParameters } from "../../types";
import { maxFetchedTrades, TaskStatus } from "../../Hooks/tradesFetcher";
import { Tooltip } from "../Tooltip";
import { SearchHistorySelect } from "./SearchHistorySelect";
import { TaskStatusStack } from "./TaskStatusStack";
import RestartAltSharpIcon from "@mui/icons-material/RestartAltSharp";
import KeyboardArrowLeftSharpIcon from "@mui/icons-material/KeyboardArrowLeftSharp";
import KeyboardArrowRightSharpIcon from "@mui/icons-material/KeyboardArrowRightSharp";
import { ErrorTypography } from "../Typography";

interface DrawerProps {
    open?: boolean;
    setOpen?: (open: boolean) => void;
    onSearch?: (parameters: SearchParameters) => void;
    tasks?: TaskStatus[];
    onCancel?: () => void;
    searchHistory?: SearchParameters[];
    reachedFetchedTradesLimit: boolean;
}

const OpenableDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    "& .MuiDrawer-paper": {
        padding: theme.spacing(1),
        height: "100vh",
        position: "relative",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        boxSizing: "border-box",
        ...(!open && {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(6),
        }),
        backgroundColor: theme.palette.trayport.contrastBackgroundThemeColor,
        "&::-webkit-scrollbar": {
            width: theme.spacing(1),
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: theme.palette.trayport.contrastBackgroundThemeColor,
        },
        "&::-webkit-scrollbar-thumb": {
            backgroundColor: theme.palette.trayport.scrollbarThumb,
        },
        "&::-webkit-scrollbar-thumb:hover": {
            backgroundColor: theme.palette.trayport.table.hoverSelectedTrades,
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: theme.palette.trayport.backgroundThemeColor,
        },
    },
}));

const evaluateDateRange = (dateRange: DateTimeRangePicked): DateTimeRange | null => {
    if (dateRange.range.from === null || dateRange.range.until === null) {
        return null;
    } else if(!dateRange.range.from.isValid() || !dateRange.range.until.isValid()){
        return null;
    } else if (dateRange.range.from > dateRange.range.until) {
        return null;
    } else {
        return { from: dateRange.range.from, until: dateRange.range.until };
    }
};

export const InstrumentSearchDrawer: React.FC<DrawerProps> = ({
    open = true,
    setOpen = () => null,
    onSearch = () => null,
    onCancel = () => null,
    tasks = [],
    searchHistory = [],
    reachedFetchedTradesLimit = false,
}) => {
    const [dateRange, setDateRange] = useState<DateTimeRangePicked>(defaultDateTimeRangePicked);
    const {
        reset: resetInstrument,
        props,
        isValid: isInstrumentValid,
        result: instrumentResult,
        setSearch,
    } = useInstrumentSearch(dateRange.range.from ?? undefined);

    const handleReset = () => {
        setDateRange(defaultDateTimeRangePicked);
        resetInstrument();
    };

    const handleSearchClick = () => {
        const rangeEval = evaluateDateRange(dateRange);
        if (
            rangeEval === null ||
            !isInstrumentValid ||
            !instrumentResult.instrument ||
            !instrumentResult.sequence ||
            !instrumentResult.sequenceItem
        ) {
            return; // TODO: set errors
        }
        onSearch({
            instrument: instrumentResult.instrument,
            sequence: instrumentResult.sequence,
            sequenceItem: instrumentResult.sequenceItem,
            secondSequenceItem: instrumentResult.secondSequenceItem,
            contractType: instrumentResult.contractType,
            ...rangeEval,
        } as SearchParameters);
    };

    const isValid = isInstrumentValid && evaluateDateRange(dateRange) !== null;
    const drawerKeyboardProps = useKeyboardListener(handleSearchClick);

    const OpenCloseButton = (
        <Tooltip title={open ? "Close search" : "Open search"} arrow>
            <IconButton onClick={() => setOpen(!open)} style={{ padding: 3 }}>
                {open ? <KeyboardArrowLeftSharpIcon /> : <KeyboardArrowRightSharpIcon />}
            </IconButton>
        </Tooltip>
    );
    const isDataFetching = tasks.some((task) => ["notStarted", "fetching"].includes(task.status));

    return (
        <OpenableDrawer variant="permanent" open={open} {...drawerKeyboardProps}>
            {open ? (
                <Toolbar style={{ paddingLeft: 10, paddingRight: 10, margin: 0, minHeight: 32 }}>
                    <Tooltip arrow title="Reset search parameters">
                        <IconButton onClick={handleReset} style={{ padding: 3 }}>
                            <RestartAltSharpIcon />
                        </IconButton>
                    </Tooltip>
                    <div style={{ flexGrow: 1 }}></div>

                    {OpenCloseButton}
                </Toolbar>
            ) : (
                OpenCloseButton
            )}

            {open && (
                <>
                    <Stack spacing={2}>
                        <InstrumentSearch {...props} />
                        <DateRangePicker onChange={setDateRange} value={dateRange} />
                        {isDataFetching ? (
                            <CancelButton color="secondary" onClick={onCancel}>
                                Cancel
                            </CancelButton>
                        ) : (
                            <Button
                                color="primary"
                                disabled={!isValid}
                                onClick={handleSearchClick}
                                sx={{ flexGrow: 1, marginRight: 1 }}
                            >
                                Search trades
                            </Button>
                        )}
                        <SearchHistorySelect
                            searchHistory={searchHistory}
                            onApplyHistory={(parameters, timeRange) => {
                                setSearch(parameters);
                                setDateRange(timeRange);
                            }}
                            currentInstrument={instrumentResult}
                            currentTime={dateRange}
                        />
                    </Stack>
                    {reachedFetchedTradesLimit && (
                        <ErrorTypography align="justify" variant="caption">
                            This tool can't fetch more than {maxFetchedTrades.toLocaleString()} trades.
                        </ErrorTypography>
                    )}

                    <div style={{ flexGrow: 1, minHeight: 50 }}></div>
                    <TaskStatusStack tasks={tasks} />
                </>
            )}
        </OpenableDrawer>
    );
};
