import { Dispatch } from "react";
import { Instrument, Market } from "../../../types";
import fuzzysort from "fuzzysort";
import { stripMarketMarker, instrumentNameWithMarker } from "./utils";
import { SearchState, SearchStateAction } from "./searchState";
import { searchOptionsLimit } from "./constants";

export const instrumentSearch = ({
    refData,
    search,
    dispatch,
}: {
    refData: {
        instruments?: Instrument[];
        markets?: Market[];
    };
    search: SearchState;
    dispatch: Dispatch<SearchStateAction>;
}) => {
    const {
        input: { instrument: instrumentInput },
    } = search;

    const findInstMarket = (_input: string): Instrument | Market | null => {
        const [name, isMarket] = stripMarketMarker(_input);
        const instrumentsOrMarkets =
            (isMarket ? refData.markets : refData.instruments) || ([] as (Instrument | Market)[]);
        return (
            instrumentsOrMarkets.find(
                (inst: Instrument | Market) =>
                    inst.name.toLowerCase() === name.toLowerCase() || (/^\d+$/.test(name) && parseInt(name) === inst.id)
            ) ||
            refData.instruments?.find((inst) => /^\d+$/.test(name) && parseInt(name) === inst.id) ||
            refData.markets?.find((inst) => /^\d+$/.test(name) && parseInt(name) === inst.id) ||
            null
        );
    };

    const handleInstrumentInputChange = (_instrumentInput: string) => {
        const exactMatch = findInstMarket(_instrumentInput);
        if (exactMatch) {
            dispatch({ type: "setInstrument", instMarket: exactMatch });
        } else {
            dispatch({ type: "setInstrumentInput", input: _instrumentInput });
        }
    };

    const handleInstrumentInputBlur = (_instrumentInput: string) => {
        const exactMatch = findInstMarket(_instrumentInput);
        if (exactMatch) {
            dispatch({ type: "setInstrument", instMarket: exactMatch });
        } else {
            dispatch({ type: "setInstrumentInvalid", input: _instrumentInput });
        }
    };

    const instrumentSearchOptions = (() => {
        const instMarkets = [...(refData.markets || []), ...(refData.instruments || [])];

        const [fixedInstrumentInput] = stripMarketMarker(instrumentInput);

        const improvedSpreadName = fixedInstrumentInput.includes("/")
            ? fixedInstrumentInput.replace(/(\s)?\/(\s)?/, " / ")
            : fixedInstrumentInput;

        const searchInput = improvedSpreadName.toLowerCase();

        if (searchInput === "") {
            return instMarkets.slice(0, searchOptionsLimit).map(instrumentNameWithMarker);
        }

        const sorted = fuzzysort.go(
            searchInput,
            instMarkets.map((inst) => ({
                inst,
                name: inst.name.replace(/(\s)?\/(\s)?/, "/"),
            })) || [],
            {
                keys: ["name"],
                limit: searchOptionsLimit,
                all: true,
                scoreFn: (a) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const inst: Instrument | Market = (a as any).obj.inst;
                    if (inst.name.toLowerCase() === searchInput) {
                        return 1_000_000 + (inst.type === "market" ? 50_000 : 0);
                    }
                    if (/^\d+$/.test(searchInput) && parseInt(searchInput) === inst.id) {
                        return 900_000 + (inst.type === "instrument" ? 50_000 : 0);
                    }
                    const [nameMatch] = a;
                    const weight =
                        (inst.type === "market" ? 100 : 0) +
                        (searchInput.includes("/") === inst.name.includes("/") ? 1000 : 0);
                    if (nameMatch === null) {
                        return -100000 + weight - inst.name.charCodeAt(0);
                    }
                    return nameMatch.score + weight;
                },
            }
        );
        return sorted.map((result) => result.obj.inst).map(instrumentNameWithMarker);
    })();

    return {
        handleInstrumentInputChange,
        handleInstrumentInputBlur,
        instrumentSearchOptions,
    };
};
