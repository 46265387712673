import React, { useEffect } from "react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { useSearchParams } from "react-router-dom";
import { lightTheme, darkTheme } from "../Themes/theme";

type ThemeMode = "light" | "dark";

const parseThemeMode = (val?: string | null): ThemeMode | null =>
    val === "dark" ? "dark" : val === "light" ? "light" : null;

const DEFAULT_THEME = parseThemeMode(process.env.REACT_APP_DEFAULT_THEME) ?? "light";

const useThemeModeSelector = (): ThemeMode => {
    const [searchParameters, setSearchParams] = useSearchParams();

    const themeValue = searchParameters.get("theme");
    const themeMode = parseThemeMode(themeValue) ?? DEFAULT_THEME;

    useEffect(() => {
        if(themeValue === themeMode){
            return;
        }
        searchParameters.set("theme", themeMode);
        setSearchParams(searchParameters);
    }, [setSearchParams, searchParameters, themeMode]);

    return themeMode;
};

interface Props {
    children: React.ReactNode;
}

const ThemeProvider: React.FC<Props> = ({ children }) => {
    const themeMode = useThemeModeSelector();
    const theme = themeMode === "light" ? lightTheme : darkTheme;

    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};

export default ThemeProvider;

export const LightThemeProvider: React.FC<Props> = ({ children }) => (
    <MuiThemeProvider theme={lightTheme}>{children}</MuiThemeProvider>
);

export const DarkThemeProvider: React.FC<Props> = ({ children }) => (
    <MuiThemeProvider theme={darkTheme}>{children}</MuiThemeProvider>
);
