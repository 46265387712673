import React from "react";
import { ToggleButtonGroup, ToggleButton } from "./ToggleButtonGroup";
import { SearchField } from "./SearchField";
import { ContractType } from "../types";
import { IconButton } from "@mui/material";
import SwapVertSharpIcon from "@mui/icons-material/SwapVertSharp";
import { Stack } from "@mui/system";

type SinglePeriodSequenceItemInput = {
    contractType: "SinglePeriod";
    sequenceItemId: string;
};

type DoublePeriodSequenceItemInput = {
    contractType: "Spread" | "Range";
    sequenceItemId: string;
    secondSequenceItemId: string;
};

export type SequenceItemInput = SinglePeriodSequenceItemInput | DoublePeriodSequenceItemInput;
export const defaultSequenceItemInput: SequenceItemInput = {
    contractType: "SinglePeriod",
    sequenceItemId: "",
};
export type SequenceItemInputFields = keyof DoublePeriodSequenceItemInput;

interface Props {
    value?: SequenceItemInput;
    onChange: (sequenceItem: SequenceItemInput, changedField: SequenceItemInputFields) => void;
    onBlur?: (sequenceItem: SequenceItemInput, changedField: Exclude<SequenceItemInputFields, "contractType"> | "sequenceItems") => void;
    sequenceItemError?: string;
    secondSequenceItemError?: string;
    disabled?: boolean;
    sequenceItemSearchOptions?: string[];
    secondSequenceItemSearchOptions?: string[];
    allowSwap?: boolean;
    topHelperText: string;
}

export const SequenceItemSelector: React.FC<Props> = ({
    value = defaultSequenceItemInput,
    onChange = () => null,
    onBlur = () => null,
    sequenceItemError,
    secondSequenceItemError,
    disabled,
    sequenceItemSearchOptions = [],
    secondSequenceItemSearchOptions = [],
    allowSwap = false,
    topHelperText = "",
}) => {
    const handleSequenceItemChanged = (v: string) => {
        onChange({ ...value, sequenceItemId: v }, "sequenceItemId");
    };

    const handleSecondSequenceItemChanged = (v: string) => {
        if (value.contractType === "SinglePeriod") {
            return;
        }
        onChange({ ...value, secondSequenceItemId: v }, "secondSequenceItemId");
    };

    const handlePeriodChanged = (v: ContractType | null) => {
        const _contractType = v || value.contractType;
        if (_contractType === "SinglePeriod") {
            onChange({ ...value, contractType: _contractType }, "contractType");
        } else {
            onChange({ ...value, contractType: _contractType, secondSequenceItemId: "" }, "contractType");
        }
    };

    return (
        <>
            <SearchField
                searchOptions={sequenceItemSearchOptions || []}
                label="Period"
                onInputChange={handleSequenceItemChanged}
                value={value.sequenceItemId}
                error={!!sequenceItemError}
                helperText={sequenceItemError || ""}
                disabled={disabled}
                onBlur={() => onBlur(value, "sequenceItemId")}
                filterDisabled={true}
                topHelperText={topHelperText}
            />
            <Stack direction="row">
                <ToggleButtonGroup
                    color="primary"
                    value={value.contractType}
                    onChange={(e, newValue: ContractType | null) => handlePeriodChanged(newValue)}
                    exclusive
                    disabled={disabled}
                    sx={{flexGrow: 1}}
                >
                    <ToggleButton value="SinglePeriod">Single</ToggleButton>
                    <ToggleButton value="Spread">Spread</ToggleButton>
                    <ToggleButton value="Range">Range</ToggleButton>
                </ToggleButtonGroup>
                {allowSwap && value.contractType !== "SinglePeriod" && (
                    <IconButton
                        sx={{ padding: 0.2 }}
                        onClick={() => {
                            const _value = {
                                ...value,
                                sequenceItemId: value.secondSequenceItemId,
                                secondSequenceItemId: value.sequenceItemId,
                            };
                            onBlur(_value, "sequenceItems");
                        }}
                    >
                        <SwapVertSharpIcon sx={{ fontSize: "20px" }} />
                    </IconButton>
                )}
            </Stack>
            {value.contractType !== "SinglePeriod" && (
                <SearchField
                    searchOptions={secondSequenceItemSearchOptions || []}
                    label="Second Period"
                    onInputChange={handleSecondSequenceItemChanged}
                    value={value.secondSequenceItemId}
                    error={!!secondSequenceItemError}
                    helperText={secondSequenceItemError}
                    disabled={disabled}
                    onBlur={() => onBlur(value, "secondSequenceItemId")}
                    filterDisabled={true}
                />
            )}
        </>
    );
};
