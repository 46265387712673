import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import InputAdornment from "@mui/material/InputAdornment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Typography } from "./Typography";

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
    ".MuiFormLabel-root": {
        fontWeight: "bold",
        padding: theme.spacing(1),
    },
    ".MuiAutocomplete-inputRoot": {
        borderRadius: "0px",
        backgroundColor: theme.palette.trayport.backgroundSearchElements,
        '&[class*="MuiOutlinedInput-root"] .MuiAutocomplete-input:first-of-type': {
            paddingTop: 0,
            paddingBottom: 0,
        },
        "& .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.trayport.backgroundSearchElements,
        },
        "&.Mui-focused, &:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.trayport.hoverSearchElements,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: theme.palette.trayport.hoverSearchElements,
        },
        "&.Mui-disabled": {
            backgroundColor: theme.palette.trayport.disabledColor,
            "&.Mui-focused, &:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
        },
        "&.Mui-error": {
            "&.Mui-focused, &:hover .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-notchedOutline": {
                borderColor: "#f00",
            },
        },
    },
}));

interface Props {
    searchOptions: string[];
    label?: string;
    value?: string;
    onInputChange?: (inputValue: string) => void;
    onBlur?: (inputValue: string) => void;
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
    filterDisabled?: boolean;
    topHelperText?: string;
}

export const SearchField: React.FC<Props> = ({
    searchOptions,
    label,
    value = "",
    onInputChange = () => null,
    error,
    helperText,
    onBlur = () => null,
    disabled,
    filterDisabled,
    topHelperText = "",
}) => {
    return (
        <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <Typography variant="caption" sx={{ fontWeight: "bold" }}>
                    {label}
                </Typography>
                <Typography variant="caption" sx={{ opacity: 0.5 }}>
                    {topHelperText}
                </Typography>
            </div>
            <StyledAutocomplete
                freeSolo
                options={searchOptions}
                inputValue={value}
                onInputChange={(e, newValue) => onInputChange(newValue)}
                disabled={disabled}
                {...(filterDisabled ? { filterOptions: (x) => x } : {})}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FontAwesomeIcon icon={faFilter} />
                                </InputAdornment>
                            ),
                            onBlur: () => onBlur(value),
                            style: { padding: 3 },
                        }}
                        disabled={disabled}
                        error={error}
                        helperText={helperText}
                    />
                )}
            />
        </div>
    );
};
