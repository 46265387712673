import React from "react";
import { styled } from "@mui/material/styles";
import MuiButton, { ButtonProps } from "@mui/material/Button";

const StyledButton = styled(MuiButton)(({ theme }) => ({
    borderRadius: 0,
    '&:hover': {
        backgroundColor: theme.palette.trayport.hoverSearchElements,
    },
}));

const StyledCancelButton = styled(MuiButton)(({ theme }) => ({
    borderRadius: 0,
    '&:hover': {
        backgroundColor: theme.palette.trayport.table.hoverSelectedTrades,
        color: theme.palette.trayport.contrastBackgroundThemeColor,
    },
}));

export const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <StyledButton variant="contained" {...props}>
            {children}
        </StyledButton>
    );
};

export const CancelButton: React.FC<ButtonProps> = ({ children, ...props }) => {
    return (
        <StyledCancelButton variant="contained" {...props}>
            {children}
        </StyledCancelButton>
    );
};
