import MuiTypography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

export const Typography = styled(MuiTypography)(() => ({}));

export const ClickableTypography = styled(MuiTypography)(({ theme }) => ({
    borderRadius: "2px",
    alignSelf: "flex-start",
    cursor: "pointer",
    fontSize: "0.8rem",
    ":hover": {
        backgroundColor: theme.palette.trayport.table.hoverUnselectedTrades,
    },
    ":active": {
        backgroundColor: theme.palette.trayport.hoverSearchElements,
    },
}));

export const ErrorTypography = styled(MuiTypography)(({ theme }) => ({
    alignSelf: "flex-start",
    cursor: "default",
    fontSize: "0.8rem",
    wordBreak: "normal",
    whiteSpace: "normal",
    textJustify: "inter-character",
    color: theme.palette.error.main,
}));
