import React, { useState } from "react";
import Box from "@mui/material/Box";
import { InstrumentSearchDrawer, StatisticsAppBar, TradesGrid } from "../../Components";
import { useTradesFetcher } from "../../Hooks/tradesFetcher";
import { useApiConfig } from "../../Hooks/config";
import { Trade } from "../../types";
import { useSearchHistory } from "../../Hooks/useSearchHistory";

const TradesDashboard: React.FC = () => {
    const [open, setOpen] = useState<boolean>(true);
    const [selectedTrades, setSelectedTrades] = useState<Trade[]>([]);
    const [filteredTrades, setFilteredTrades] = useState<Trade[]>([]);
    const { searchHistory, appendSearch } = useSearchHistory();
    const config = useApiConfig();
    const fetcher = useTradesFetcher(config);

    const trades: Trade[] = fetcher.tasks
        .filter((task) => task.data !== undefined)
        .map((task) => task.data)
        .flat()
        .filter((trade) => trade !== undefined)
        .map((trade) => trade as Trade);

    return (
        <Box sx={{ display: "flex" }}>
            <InstrumentSearchDrawer
                open={open}
                setOpen={setOpen}
                onSearch={(searchParams) => {
                    appendSearch(searchParams);
                    fetcher.dispatchRequest(searchParams);
                }}
                tasks={fetcher.tasks}
                onCancel={fetcher.cancel}
                searchHistory={searchHistory}
                reachedFetchedTradesLimit={fetcher.reachedLimit}
            />
            <TradesGrid
                trades={trades}
                selectedTrades={selectedTrades}
                onSelectionChange={setSelectedTrades}
                searchParameters={fetcher.currentQuery}
                onFilterChange={setFilteredTrades}
            />
            <StatisticsAppBar
                open={open}
                trades={filteredTrades}
                selectedTrades={selectedTrades}
                units={fetcher.tradingSpecifications}
            />
        </Box>
    );
};

export default TradesDashboard;
