import { Moment } from "moment";

export type MomentRange = [Moment, Moment];

export const dateRangeSplitter = (from: Moment, until: Moment, maxSearchRangeDays: number): MomentRange[] => {
    let _until = until;
    const ranges: MomentRange[] = [];
    while (_until.clone().subtract(maxSearchRangeDays, "days") > from) {
        const _from = _until.clone().subtract(maxSearchRangeDays, "days");
        ranges.push([_from, _until]);
        _until = _from;
    }
    ranges.push([from, _until]);

    return ranges;
};

export const formatMomentWithNoMillis = (dt: Moment) => dt.toISOString().split(".")[0] + "Z";
