import { useQuery, useQueries } from "react-query";
import { ApiConfiguration } from "../config";
import { Instrument, Market } from "../../types";
import RefDataApi from "./refDataApi";
import { fixSequenceItems, fixSequences, sequenceItemDefaultSort } from "./utils";
import { SequenceItemWithSequence } from "../../types";
import moment from "moment";
import { useMemo } from "react";
import epexSequences from "../../Resources/EPEXSequences.json";

export const useRefData = (config: ApiConfiguration) => {
    const api = new RefDataApi(config);
    const instruments = useQuery(["instruments", config.refDataApiEndpoint, config.apiKey ?? ""], api.instruments());
    const markets = useQuery(["markets", config.refDataApiEndpoint, config.apiKey ?? ""], api.markets());

    const useInstrumentSequences = (instOrMarket: Instrument | Market | null, fromDate: moment.Moment | undefined) => {
        const sequencesQueryResult = useQuery(
            ["sequences", instOrMarket?.type, instOrMarket?.id, config.refDataApiEndpoint, config.apiKey ?? ""],
            async () => {
                const sqis = await api.sequences(instOrMarket)();
                return fixSequences(sqis);
            },
            {
                enabled: !!instOrMarket,
            }
        );

        const epexSequencesSet = new Set(epexSequences.EpexSequences);
        const fromDateString = fromDate?.format("YYYY-MM-DDThh:mm:ss[Z]");

        const sequences = (sequencesQueryResult.data || []).map((sq) => ({
            ...sq,
            isEpexSequence: epexSequencesSet.has(sq.id),
        }));

        const sequenceItems = useQueries(
            sequences.map((sequence) => ({
                queryKey: ["sequenceItem", sequence.id, sequence.isEpexSequence ? fromDateString : undefined],
                queryFn: async () => {
                    const sequenceItems = await api.sequenceItems(
                        sequence,
                        sequence.isEpexSequence ? fromDate ?? moment("2012-01-01") : undefined,
                        sequence.isEpexSequence ? 1000 : undefined
                    )();
                    const uniqueSequenceItems = fixSequenceItems(
                        sequenceItems.map((sqi) => ({
                            ...sqi,
                            periodStart: sqi.periodStart ? moment(sqi.periodStart) : undefined,
                            periodEnd: sqi.periodEnd ? moment(sqi.periodEnd) : undefined,
                        }))
                    );
                    return uniqueSequenceItems.map((sqi) => ({ ...sqi, sequence } as SequenceItemWithSequence));
                },
                enabled: !!sequences,
            }))
        );

        const sequenceItemsWithSequence = useMemo(
            () => sequenceItemDefaultSort(sequenceItems.map((r) => r.data || []).flat(), sequences),
            [
                sequenceItems
                    .map((r) => r.data?.map((sqi) => sqi.id))
                    .flat()
                    .join(""),
            ]
        );

        return {
            sequencesLoading: sequencesQueryResult.isLoading,
            allLoading: sequencesQueryResult.isLoading || sequenceItems.some((sq) => sq.isLoading),
            sequences: sequences,
            sequenceItems: sequenceItemsWithSequence,
        };
    };

    const useInstrumentTradingSpecifications = (instOrMarket: Instrument | Market | null) => {
        const details = useQuery(
            ["details", instOrMarket?.type, instOrMarket?.id, config.refDataApiEndpoint, config.apiKey ?? ""],
            api.details(instOrMarket),
            {
                enabled: !!instOrMarket,
            }
        );

        return {
            isLoading: details.isLoading,
            tradingSpecifications: details.data?.tradingSpecifications ?? null,
        };
    };

    return {
        isLoading: instruments.isLoading || markets.isLoading,
        isError: instruments.isError || markets.isError,
        error: instruments.error || markets.error,
        instruments: instruments.data,
        markets: markets.data,
        useInstrumentSequences,
        useInstrumentTradingSpecifications,
    };
};
