import { styled } from "@mui/material/styles";
import { DataGridPro as MuiDataGrid } from "@mui/x-data-grid-pro";

export const DataGrid = styled(MuiDataGrid)(({ theme }) => ({
    ".MuiDataGrid-columnHeaders": {
        backgroundColor: theme.palette.trayport.contrastBackgroundThemeColor,
    },
    ".MuiDataGrid-row:nth-of-type(even)": {
        backgroundColor: theme.palette.trayport.contrastBackgroundThemeColor,
    },
    ".MuiDataGrid-row:nth-of-type(odd)": {
        backgroundColor: theme.palette.trayport.backgroundThemeColor,
    },
    ".trades-grid-private.MuiDataGrid-row": {
        backgroundColor: theme.palette.trayport.table.privateTradesHighlight,
        color: theme.palette.trayport.table.privateTradesText,
        "&.Mui-selected:hover": {
            backgroundColor: theme.palette.trayport.table.privateTradesHighlight,
            color: theme.palette.trayport.table.privateTradesText,
        },
    },
    ".MuiDataGrid-row.Mui-selected:hover": {
        backgroundColor: theme.palette.trayport.table.hoverSelectedTrades,
    },
    ".MuiDataGrid-row.Mui-selected, .MuiDataGrid-row:hover": {
        backgroundColor: theme.palette.trayport.table.hoverUnselectedTrades,
        color: theme.palette.trayport.backgroundThemeColor,
    },
    ".MuiDataGrid-cell:focus": {
        outline: "0"
    },
    ".MuiDataGrid-virtualScroller::-webkit-scrollbar": {
        width: theme.spacing(2),
    },
    ".MuiDataGrid-virtualScroller::-webkit-scrollbar-track": {
        backgroundColor: theme.palette.trayport.contrastBackgroundThemeColor,
    },
    ".MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
        backgroundColor: theme.palette.trayport.scrollbarThumb,
    },
    ".MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover": {
        backgroundColor: theme.palette.trayport.table.hoverSelectedTrades,
    },
    ".MuiDataGrid-virtualScroller::-webkit-scrollbar-corner": {
        backgroundColor: theme.palette.trayport.backgroundThemeColor,
    },
}));
