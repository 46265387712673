import { createTheme, darken, lighten } from "@mui/material/styles";

declare module "@mui/material/styles" {
    interface Palette {
        trayport: {
            disabledColor: string;
            scrollbarThumb: string;
            hoverSearchElements: string;
            backgroundSearchElements: string;
            backgroundThemeColor: string;
            contrastBackgroundThemeColor: string;
            table: {
                hoverSelectedTrades: string;
                hoverUnselectedTrades: string;
                privateTradesHighlight: string;
                privateTradesText: string;
            };
        };
    }
    interface PaletteOptions {
        trayport: {
            disabledColor: string;
            scrollbarThumb: string;
            hoverSearchElements: string;
            backgroundSearchElements: string;
            backgroundThemeColor: string;
            contrastBackgroundThemeColor: string;
            table: {
                hoverSelectedTrades: string;
                hoverUnselectedTrades: string;
                privateTradesHighlight: string;
                privateTradesText: string;
            };
        };
    }
}

export const lightTheme = createTheme({
    palette: {
        mode: "light",
        //Primary is used to change the color of the search button
        primary: {
            main: "#3CA1D5",
            contrastText: "#FFFFFF",
        },
        //Secondary is used to change the color of the cancel button (which appears while fetching data)
        secondary: {
            main: "#AABBC4",
            contrastText: "#1D3C77",
        },
        //Text changes the color of all text (except the ones stated in below comments)
        text: {
            primary: "#1D3C77",
        },
        trayport: {
            backgroundThemeColor: "#FFFFFF", //Changes color of trades box background, stats background, text of selected trades, highlight of odd (unselected) rows, text of hovered trades and text of selected toggle button
            contrastBackgroundThemeColor: "#EEF1F3", //Changes color of search drawer and scrollbar background, background of trades header and even (unselected) rows
            disabledColor: "#D1D4D5",
            scrollbarThumb: "#C5CDD2",
            hoverSearchElements: "#1D3C77", //Also changes color of clicked stats
            backgroundSearchElements: "#D5DDE2",
            table: {
                hoverSelectedTrades: darken("#3CA1D5", 0.2), //Also changes color of hovered scrollbar thumb
                hoverUnselectedTrades: "#3CA1D5", //Also changes color of hovered stats and background of selected trades
                privateTradesHighlight: "#C0DCC0",
                privateTradesText: "#000000",
            },
        },
    },
    typography: {
        fontFamily: "Arial",
    },
    transitions: {
        duration: {
            enteringScreen: 50,
            leavingScreen: 50
        }
    }
});

export const darkTheme = createTheme({
    palette: {
        mode: "dark",
        //Primary is used to change the color of the search button, and selected toggle button
        primary: {
            main: "#3CA1D5",
            contrastText: "#FFFFFF",
        },
        //Secondary is used to change the color of the cancel button (which appears while fetching data)
        secondary: {
            main: "#AABBC4",
            contrastText: "#1D3C77",
        },
        //Text changes the color of all text (except the ones stated in below comments)
        text: {
            primary: "#FFFFFF",
        },
        trayport: {
            backgroundThemeColor: "#000000", //Changes color of trades box background, stats background, text of selected trades, highlight of odd (unselected) rows, text of hovered trades and text of selected toggle button
            contrastBackgroundThemeColor: "#222222", //Changes color of search drawer and scrollbar background, highlight of trades header and even (unselected) rows
            disabledColor: "#3D3D3D",
            scrollbarThumb: "#444444",
            hoverSearchElements: "#1D3C77", //Also changes color of clicked stats
            backgroundSearchElements: "#5D7380",
            table: {
                hoverSelectedTrades: lighten("#3CA1D5", 0.2), //Also changes color of hovered scrollbar thumb
                hoverUnselectedTrades: "#3CA1D5", //Also changes color of hovered stats
                privateTradesHighlight: "#C0DCC0",
                privateTradesText: "#000000",
            },
        },
    },
    typography: {
        fontFamily: "Arial",
    },
    transitions: {
        duration: {
            enteringScreen: 50,
            leavingScreen: 50
        }
    }
});
