import { formatMomentWithNoMillis } from "./utils";
import { ApiConfiguration } from "../config";
import { TradesParameters, RawTrade } from "./types";
import { USER_AGENT } from "../userAgent";

export default class TradesApi {
    config: ApiConfiguration;

    constructor(config: ApiConfiguration) {
        this.config = config;
    }

    get<T>(query: string): () => Promise<T> {
        return async () => {
            const res = await fetch(`${this.config.tradesApiEndpoint}${query}`, {
                headers: {
                    ...(this.config.apiKey ? { "x-api-key": this.config.apiKey } : {}),
                    "User-Agent": USER_AGENT,
                },
            });
            const rawData = await res.text();
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let data: any;

            try{
                data = JSON.parse(rawData);
            } catch (e) {
                throw new Error(rawData);
            }

            if (!res.ok) {
                throw new Error(data.title ?? res.statusText);
            }

            return data;
        };
    }

    tradesQueryString(params: TradesParameters): string {
        return this.config.tradesApiEndpoint + this.tradesQueryParametersString(params);
    }

    tradesQueryParametersString(params: TradesParameters): string {
        const endpoint = "/api/trades";

        const queryString = new URLSearchParams();
        queryString.append("from", `${formatMomentWithNoMillis(params.from)}`);
        queryString.append("until", `${formatMomentWithNoMillis(params.until)}`);

        if (params.includePrivate !== undefined) {
            queryString.append("includePrivate", `${params.includePrivate}`);
        }
        if (params.optionalFields) {
            queryString.append("optionalFields", params.optionalFields.join(","));
        }

        if (params.instrumentId) {
            queryString.append("instrumentId", `${params.instrumentId}`);
        } else {
            queryString.append("marketId", `${params.marketId}`);
        }
        queryString.append("sequenceId", `${params.sequenceId}`);
        queryString.append("sequenceItemId", `${params.sequenceItemId}`);
        if (params.secondSequenceItemId) {
            queryString.append("secondSequenceItemId", `${params.secondSequenceItemId}`);
        }
        queryString.append("contractType", `${params.contractType}`);
        if (params.routes?.length) {
            queryString.append("routes", params.routes.join(","));
        }

        return `${endpoint}?${queryString}`;
    }

    trades(params: TradesParameters): () => Promise<RawTrade[]> {
        return () => this.get<RawTrade[]>(this.tradesQueryParametersString(params))();
    }
}
