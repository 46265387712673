import { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import { GridFilterInputValueProps, GridFilterItem, GridFilterOperator } from "@mui/x-data-grid-pro";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import moment, { Moment } from "moment";
import TextField from "@mui/material/TextField";
import { ValueSelectWithLabel } from "../Select";

const SUBMIT_FILTER_STROKE_TIME = 500;

const DateTimeFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const filterTimeout = useRef<NodeJS.Timeout | null>();
    const [filterValueState, setFilterValueState] = useState<Moment | null>(
        moment.isMoment(item.value) ? item.value : null
    );

    useEffect(() => {
        return () => {
            if (filterTimeout.current) {
                clearTimeout(filterTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        const itemValue = moment.isMoment(item.value) ? item.value : null;
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (_value: Moment | null) => {
        if (filterTimeout.current) {
            clearTimeout(filterTimeout.current);
        }
        console.log(_value);
        setFilterValueState(_value);

        filterTimeout.current = setTimeout(() => {
            applyValue({ ...item, value: _value });
        }, SUBMIT_FILTER_STROKE_TIME);
    };

    return (
        <Box
            sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "end",
                height: 48,
                width: 185,
            }}
        >
            <DateTimePicker
                value={filterValueState}
                onChange={(_value) => updateFilterValue(_value)}
                renderInput={(props) => <TextField variant="standard" {...props} label="Value" InputLabelProps={{ shrink: true }}/>}
                inputFormat={"YYYY/MM/DD HH:mm"}
                ampm={false}
            />
        </Box>
    );
};

const TimeFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const filterTimeout = useRef<NodeJS.Timeout | null>(null);
    const [filterValueState, setFilterValueState] = useState<Moment | null>(
        moment.isMoment(item.value) ? item.value : null
    );

    useEffect(() => {
        return () => {
            if (filterTimeout.current) {
                clearTimeout(filterTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        const itemValue = moment.isMoment(item.value) ? item.value : null;
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (_value: Moment | null) => {
        if (filterTimeout.current) {
            clearTimeout(filterTimeout.current);
        }
        setFilterValueState(_value);

        filterTimeout.current = setTimeout(() => {
            applyValue({ ...item, value: _value });
        }, SUBMIT_FILTER_STROKE_TIME);
    };

    return (
        <Box
            sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "end",
                height: 48,
                width: 185,
            }}
        >
            <TimePicker
                value={filterValueState}
                onChange={(_value) => updateFilterValue(_value)}
                renderInput={(props) => <TextField variant="standard" {...props} />}
                inputFormat={"HH:mm:ss"}
                ampm={false}
            />
        </Box>
    );
};

const BuySellFilter = (props: GridFilterInputValueProps) => {
    const { item, applyValue } = props;

    const filterTimeout = useRef<NodeJS.Timeout>();
    const [filterValueState, setFilterValueState] = useState<boolean>(!!item.value);

    useEffect(() => {
        return () => {
            if (filterTimeout.current) {
                clearTimeout(filterTimeout.current);
            }
        };
    }, []);

    useEffect(() => {
        const itemValue = !!item.value;
        setFilterValueState(itemValue);
    }, [item.value]);

    const updateFilterValue = (_value: number) => {
        const _boolValue = _value === 1;
        if (filterTimeout.current) {
            clearTimeout(filterTimeout.current);
        }
        setFilterValueState(_boolValue);

        filterTimeout.current = setTimeout(() => {
            applyValue({ ...item, value: _boolValue });
        }, SUBMIT_FILTER_STROKE_TIME);
    };

    return (
        <Box
            sx={{
                display: "inline-flex",
                flexDirection: "row",
                alignItems: "end",
                height: 48,
                width: 185,
            }}
        >
            <ValueSelectWithLabel
                label="Value"
                variant="standard"
                selectValues={[
                    { value: 1, label: "Buy" },
                    { value: 0, label: "Sell" },
                ]}
                onValueChange={(s) => updateFilterValue(s.value)}
                value={filterValueState ? 1 : 0}
            />
        </Box>
    );
};

const momentToTimeOnly = (dt: Moment) => moment(dt.format("HH:mm:ss.f"), "HH:mm:ss.f");

export const customDateTimeFilters: GridFilterOperator[] = [
    {
        label: "after date",
        value: "after_date",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!moment.isMoment(filterItem.value) || !filterItem.value.isValid()) {
                return () => true;
            }
            return ({ value }) => {
                return value !== null && moment.isMoment(value) && value.isAfter(filterItem.value);
            };
        },
        InputComponent: DateTimeFilter,
    },
    {
        label: "before date",
        value: "before_date",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!moment.isMoment(filterItem.value) || !filterItem.value.isValid()) {
                return () => true;
            }
            return ({ value }) => {
                return value !== null && moment.isMoment(value) && value.isBefore(filterItem.value);
            };
        },
        InputComponent: DateTimeFilter,
    },
    {
        label: "after time",
        value: "after_time",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!moment.isMoment(filterItem.value) || !filterItem.value.isValid()) {
                return () => true;
            }
            return ({ value }) => {
                return (
                    value !== null &&
                    moment.isMoment(value) &&
                    momentToTimeOnly(value).isAfter(momentToTimeOnly(filterItem.value))
                );
            };
        },
        InputComponent: TimeFilter,
    },
    {
        label: "before time",
        value: "before_time",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (!moment.isMoment(filterItem.value) || !filterItem.value.isValid()) {
                return () => true;
            }
            return ({ value }) => {
                return (
                    value !== null &&
                    moment.isMoment(value) &&
                    momentToTimeOnly(value).isBefore(momentToTimeOnly(filterItem.value))
                );
            };
        },
        InputComponent: TimeFilter,
    },
];

export const customBuySellFilters: GridFilterOperator[] = [
    {
        label: "is",
        value: "is",
        getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value === null) {
                return () => true;
            }
            const filterValue = !!filterItem.value;
            return ({ value }) => {
                return value !== null && value === filterValue;
            };
        },
        InputComponent: BuySellFilter,
    },
];
