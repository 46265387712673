import React, { useEffect, useState } from "react";
import { DataGrid } from "../DataGrid";
import {
    GridColumnVisibilityModel,
    useGridApiRef,
    GridFilterModel,
    gridFilteredSortedRowEntriesSelector,
} from "@mui/x-data-grid-pro";
import { Trade, SearchParameters } from "../../types";
import { FullScreenBox } from "./FullScreenBox";
import { TradeColDef, useColumnDefinitions } from "./columnDefinitions";
import { TradeWithId, addIdsToTrades, tradesToCsv, downloadCsv } from "./utils";
import { DataGridToolbar } from "./DataGridToolbar";
import { putToClipboard } from "../../clipboard";

interface Props {
    trades: Trade[];
    selectedTrades?: Trade[];
    onSelectionChange?: (selectedTrades: Trade[]) => void;
    onFilterChange?: (filteredTrades: Trade[]) => void;
    searchParameters: SearchParameters | null;
}

export const TradesGrid: React.FC<Props> = ({
    trades,
    selectedTrades = [],
    onSelectionChange = () => null,
    searchParameters,
    onFilterChange = () => null,
}) => {
    const apiRef = useGridApiRef();

    const columns = useColumnDefinitions(trades);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>(
        columns.filter((col) => col.hideByDefault).reduce((obj, col) => ({ ...obj, [col.field]: false }), {})
    );
    const [filterModel, setFilterModel] = useState<GridFilterModel>();

    const tradesWithId: TradeWithId[] = addIdsToTrades(trades);
    const selectedTradesWithId: TradeWithId[] = addIdsToTrades(selectedTrades);

    const isOwnSet =
        filterModel?.linkOperator === "and" &&
        filterModel?.items?.some((item) => item.columnField === "isPrivateTrade" && item.value === "true");

    const setIsOwnFilter = (filterIsOwn: boolean) => {
        if (filterIsOwn && !isOwnSet) {
            setFilterModel(
                (_model) =>
                    ({
                        ..._model,
                        linkOperator: "and",
                        items: [
                            ...(_model?.items.filter((item) => item.columnField !== "isPrivateTrade") || []),
                            { columnField: "isPrivateTrade", operatorValue: "is", value: "true" },
                        ],
                    } as GridFilterModel)
            );
        } else if (!filterIsOwn) {
            setFilterModel(
                (_model) =>
                    ({
                        ..._model,
                        items: _model?.items.filter((item) => item.columnField !== "isPrivateTrade"),
                    } as GridFilterModel)
            );
        }
        updateFilteredTrades();
    };

    const getVisibleTrades = () => Array.from(apiRef.current.getVisibleRowModels().values()) as Trade[];

    const pushToClipboard = (_trades: Trade[], withHeader = true, htmlNewLine = false) => {
        const tradeFields = tradesToCsv(_trades, apiRef.current.getVisibleColumns() as TradeColDef[], withHeader, "\t", htmlNewLine ? "<br />" : "\n");
        // TODO: warn if copying more than 10k trades
        putToClipboard(tradeFields);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        const charCode = String.fromCharCode(event.which).toLowerCase();
        if ((event.ctrlKey || event.metaKey) && charCode === "c") {
            event.preventDefault();
            pushToClipboard(selectedTrades || trades, event.shiftKey, true);
        }
    };

    const downloadTradesCsv = (_trades: Trade[]) => {
        const fileName =
            `${searchParameters?.from?.toISOString()}-${searchParameters?.until?.toISOString()}-` +
            `${searchParameters?.instrument?.id}:${searchParameters?.sequence?.id}:${searchParameters?.sequenceItem?.id}:` +
            `${searchParameters?.secondSequenceItem?.id || "0"}:${searchParameters?.contractType}` +
            `.csv`;
        const csvData = tradesToCsv(_trades, apiRef.current.getAllColumns() as TradeColDef[]);

        downloadCsv(csvData, fileName);
    };

    const downloadFilteredTrades = () => {
        downloadTradesCsv(getVisibleTrades());
    };

    const copyFilteredTrades = () => {
        pushToClipboard(getVisibleTrades());
    };

    const updateFilteredTrades = () => {
        return setTimeout(() => {
            const filteredRows = gridFilteredSortedRowEntriesSelector(apiRef);
            const filteredTradeIds = filteredRows.map((row) => row.id as string);
            const filteredTrades = filteredRows.map((row) => row.model as Trade);

            onFilterChange(filteredTrades);

            if (!selectedTradesWithId.every((trade) => filteredTradeIds.includes(trade.id))) {
                onSelectionChange(selectedTradesWithId.filter((trade) => filteredTradeIds.includes(trade.id)));
            }
        }, 100);    // Delay to wait for the table contents to update...
    };

    useEffect(() => {
        const timeout = updateFilteredTrades();
        return () => clearTimeout(timeout);
    }, [selectedTradesWithId.length, tradesWithId.length]);

    const handleFilterModelChange = (filterModel: GridFilterModel) => {
        setFilterModel(filterModel);
        updateFilteredTrades();
    };

    return (
        <FullScreenBox onKeyDown={handleKeyDown}>
            <DataGrid
                sx={{ width: "100%" }}
                columns={columns}
                density="compact"
                hideFooter
                rowHeight={32}
                rows={tradesWithId}
                getRowClassName={(params) => (params.row.aggressorCompanyId !== undefined ? "trades-grid-private" : "")}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onSelectionModelChange={(newSelectionModel) => {
                    onSelectionChange(tradesWithId.filter((t) => newSelectionModel.includes(t.id)));
                }}
                selectionModel={selectedTradesWithId.map((t) => t.id)}
                components={{
                    Toolbar: () => (
                        <DataGridToolbar
                            isOwnSet={isOwnSet}
                            onIsOwnChange={setIsOwnFilter}
                            onCopy={copyFilteredTrades}
                            onDownload={downloadFilteredTrades}
                            copyDisabled={tradesWithId.length <= 0}
                        />
                    ),
                }}
                apiRef={apiRef}
                filterModel={filterModel}
                onFilterModelChange={handleFilterModelChange}
            />
        </FullScreenBox>
    );
};
