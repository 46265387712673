import React, { useState } from "react";

export const useKeyboardListener = (onEnter?: () => void) => {
    const [enterKeyDown, setEnterKeyDown] = useState<boolean>(false);

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            if (!enterKeyDown && onEnter) {
                onEnter();
            }
            setEnterKeyDown(true);
        }
    };

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            setEnterKeyDown(false);
        }
    };

    return {
        onKeyDownCapture: handleKeyDown,
        onKeyUpCapture: handleKeyUp,
    };
};
