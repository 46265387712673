import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AppConfiguration, configurationContext, defaultAppConfig } from "./config";
import { DevConfigPanel } from "../Components";

interface Props {
    children: React.ReactNode;
}

export const AppConfigurationContext: React.FC<Props> = ({ children }) => {
    const [apiConfig, setApiConfig] = useState<AppConfiguration>(defaultAppConfig);
    const [searchParams] = useSearchParams();
    const devSearchParam = searchParams.get("dev");
    const devMode = devSearchParam !== "false" && (process.env.NODE_ENV !== "production" || devSearchParam === "true");

    return (
        <configurationContext.Provider value={{ config: apiConfig, setConfig: setApiConfig }}>
            {children}
            {devMode && <DevConfigPanel />}
        </configurationContext.Provider>
    );
};
