import { useState } from "react";
import { SearchParameters } from "../types";

export const useSearchHistory = () => {
    const [searchHistory, setSearchHistory] = useState<SearchParameters[]>([]);

    const searchesEqual = (s1: SearchParameters, s2: SearchParameters) =>
        s1.instrument.id === s2.instrument.id &&
        s1.sequence.id === s2.sequence.id &&
        s1.sequenceItem.id === s2.sequenceItem.id &&
        s1.secondSequenceItem?.id === s2.secondSequenceItem?.id &&
        s1.contractType === s2.contractType &&
        s1.from.isSame(s2.from) &&
        s1.until.isSame(s2.until);

    const appendSearch = (search: SearchParameters) => {
        setSearchHistory((h) => [...h.filter((p) => !searchesEqual(p, search)), search]);
    };

    return {
        searchHistory,
        appendSearch,
    };
};
