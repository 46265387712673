import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import { configurationContext, AppConfiguration, defaultAppConfig } from "../Hooks/config";
import { Typography } from "./Typography";
import { VERSION } from "../Hooks/userAgent";

const DEV_OVERRIDE_KEY = "dev-override";

export const DevConfigPanel: React.FC = () => {
    const context = useContext(configurationContext);
    if (context === null) {
        throw new Error("DevPanel must be used within AppConfigurationContext");
    }

    const [open, setOpen] = useState<boolean>(false);
    const [localConfig, setLocalConfig] = useState<AppConfiguration>(context.config);

    useEffect(() => {
        const localConfigRaw = localStorage.getItem(DEV_OVERRIDE_KEY);
        if (!localConfigRaw) {
            return;
        }
        const localConfig = JSON.parse(localConfigRaw);

        // eslint-disable-next-line no-prototype-builtins
        if (!localConfig.hasOwnProperty("apiConfiguration")) {
            return;
        }

        const config = localConfig as AppConfiguration;
        setLocalConfig(config);
        context.setConfig(config);
    }, []);

    useEffect(() => {
        setLocalConfig(context.config);
    }, [context.config]);

    const handleClose = () => {
        setOpen(false);
    };

    const save = () => {
        context.setConfig(localConfig);
        localStorage.setItem(DEV_OVERRIDE_KEY, JSON.stringify(localConfig));
        handleClose();
    };

    const reset = () => {
        context.setConfig(defaultAppConfig);
        localStorage.removeItem(DEV_OVERRIDE_KEY);
        setLocalConfig(defaultAppConfig);
    };

    return (
        <>
            <Box sx={{ position: "absolute", top: 0, left: 40 }}>
                <Button sx={{ zIndex: 9999, padding: 0, minWidth: 20 }} onClick={() => setOpen(true)} size="small">
                    Dev
                </Button>
            </Box>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Dev configuration</DialogTitle>
                <DialogContent>
                    <DialogContentText>Configure parameters, such as API Endpoints and keys</DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="refData"
                        label="Ref Data Endpoint"
                        fullWidth
                        variant="standard"
                        value={localConfig.apiConfiguration.refDataApiEndpoint}
                        onChange={(e) =>
                            setLocalConfig((c) => ({
                                ...c,
                                apiConfiguration: { ...c.apiConfiguration, refDataApiEndpoint: e.target.value },
                            }))
                        }
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="da"
                        label="Data Analytics Trades Endpoint"
                        fullWidth
                        variant="standard"
                        value={localConfig.apiConfiguration.tradesApiEndpoint}
                        onChange={(e) =>
                            setLocalConfig((c) => ({
                                ...c,
                                apiConfiguration: { ...c.apiConfiguration, tradesApiEndpoint: e.target.value },
                            }))
                        }
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        id="da"
                        label="Data Analytics Api Key"
                        fullWidth
                        type="password"
                        variant="standard"
                        value={localConfig.apiConfiguration.apiKey || ""}
                        onChange={(e) =>
                            setLocalConfig((c) => ({
                                ...c,
                                apiConfiguration: { ...c.apiConfiguration, apiKey: e.target.value },
                            }))
                        }
                    />
                </DialogContent>
                <DialogActions>
                    <Typography sx={{ flexGrow: 1 }} variant="body2">
                        Version: {VERSION}
                    </Typography>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={reset}>Reset</Button>
                    <Button onClick={save}>Save</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
