import React from "react";
import Stack from "@mui/material/Stack";
import { TaskStatus, useTradeQueryGenerator } from "../../Hooks/tradesFetcher";
import { useApiConfig } from "../../Hooks/config";
import { TaskStatusText } from "./TaskStatusText";
import { putToClipboard } from "../../clipboard";

interface Props {
    tasks: TaskStatus[];
}

export const TaskStatusStack: React.FC<Props> = ({ tasks }) => {
    const config = useApiConfig();
    const { getTradeQuery } = useTradeQueryGenerator(config);

    const handleCopyTask = (task: TaskStatus) => {
        const tradeQuery = getTradeQuery(task.taskInfo);
        putToClipboard(tradeQuery);
    };

    return (
        <Stack>
            {tasks.map((task, i) => (
                <TaskStatusText task={task} key={i} onClick={() => handleCopyTask(task)} />
            ))}
        </Stack>
    );
};
