import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import { toolbarHeight } from "../constants";

export const FullScreenBox = styled(Box)(({ theme }) => ({
    width: "100%",
    height: `calc(100vh - ${toolbarHeight}px)`,
    padding: theme.spacing(1),
    backgroundColor: theme.palette.trayport.backgroundThemeColor
}));
