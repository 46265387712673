import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar, { AppBarProps } from "@mui/material/AppBar";
import { drawerWidth } from "../constants";
import IconButton from "@mui/material/IconButton";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";
import { Trade, TradingSpecifications } from "../../types";
import { Stats, Stat } from "./types";
import { StatisticsValue } from "./StatisticsValue";
import { copyStats } from "./utils";
import { Tooltip } from "../Tooltip";
import { Typography } from "../Typography";
import Stack from "@mui/material/Stack";
export type { Stat, Stats } from "./types";

interface OpenableAppBarProps extends AppBarProps {
    open?: boolean;
}

const emptyStats: Stat[] = [
    { name: "Count" },
    { name: "Total volume" },
    { name: "VWAP" },
    { name: "Avg Volume" },
    { name: "Open" },
    { name: "High" },
    { name: "Low" },
    { name: "Close" },
];

const OpenableAppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
})<OpenableAppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
    bottom: 0,
    top: "auto",
    boxShadow: "0 0 0 0",
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

interface Props {
    open: boolean;
    trades: Trade[];
    selectedTrades: Trade[];
    units: TradingSpecifications | null;
}

const stringOptions = { maximumFractionDigits: 2, useGrouping: false, minimumFractionDigits: 0 };

const calculateStats = (trades: Trade[]) => {
    if (trades.length < 1) return;
    const count = trades.length;
    const stats = trades.reduce(
        (_stats, current) => ({
            openTrade: current.dealDate < _stats.openTrade.dealDate ? current : _stats.openTrade,
            closeTrade: current.dealDate > _stats.closeTrade.dealDate ? current : _stats.closeTrade,
            high: current.price > _stats.high ? current.price : _stats.high,
            low: current.price < _stats.low ? current.price : _stats.low,
            vwapSum: _stats.vwapSum + current.quantity * current.price,
            totalVolume: _stats.totalVolume + current.quantity,
        }),
        {
            openTrade: trades[0],
            closeTrade: trades[0],
            high: trades[0].price,
            low: trades[0].price,
            vwapSum: 0,
            totalVolume: 0,
        }
    );
    return {
        count: count.toString(),
        volume: stats.totalVolume.toLocaleString("en-US", stringOptions),
        avgVolume: (stats.totalVolume / count).toLocaleString("en-US", stringOptions),
        vwap: (stats.vwapSum / stats.totalVolume).toLocaleString("en-US", stringOptions),
        open: stats.openTrade.price.toLocaleString("en-US", stringOptions),
        high: stats.high.toLocaleString("en-US", stringOptions),
        low: stats.low.toLocaleString("en-US", stringOptions),
        close: stats.closeTrade.price.toLocaleString("en-US", stringOptions),
    } as Stats;
};

export const StatisticsAppBar: React.FC<Props> = ({ open, trades, selectedTrades, units }) => {
    const selectedStatsMemo = useMemo(() => {
        if (selectedTrades.length > 1) {
            return calculateStats(selectedTrades);
        }
    }, [selectedTrades]);
    const allStats = useMemo(() => calculateStats(trades), [trades]);

    const stats = useMemo(() => {
        if (!allStats) {
            return emptyStats;
        }
        const selectedStats = selectedStatsMemo ?? null;
        return [
            { name: "Count", value: allStats.count, selectedValue: selectedStats?.count },
            {
                name: "Total volume",
                value: allStats.volume,
                selectedValue: selectedStats?.volume,
                unit: units?.quantityUnit,
            },
            {
                name: "Avg Volume",
                value: allStats.avgVolume,
                selectedValue: selectedStats?.avgVolume,
                unit: units?.quantityUnit,
            },
            { name: "VWAP", value: allStats.vwap, selectedValue: selectedStats?.vwap, unit: units?.priceCurrency },
            { name: "Open", value: allStats.open, selectedValue: selectedStats?.open, unit: units?.priceCurrency },
            { name: "High", value: allStats.high, selectedValue: selectedStats?.high, unit: units?.priceCurrency },
            { name: "Low", value: allStats.low, selectedValue: selectedStats?.low, unit: units?.priceCurrency },
            { name: "Close", value: allStats.close, selectedValue: selectedStats?.close, unit: units?.priceCurrency },
        ] as Stat[];
    }, [allStats, selectedStatsMemo]);

    return (
        <OpenableAppBar position="fixed" open={open}>
            <Toolbar sx={{ alignItems: "flex-end" }}>
                {selectedTrades.length > 1 ? (
                    <Typography sx={{ fontSize: "0.8rem", whiteSpace: "break-spaces" }}>
                        <b>Selected&emsp;</b>
                    </Typography>
                ) : (
                    <Typography sx={{ fontSize: "0.8rem", whiteSpace: "break-spaces" }}>
                        &emsp;&emsp;&emsp;&emsp;&emsp;
                    </Typography>
                )}
                {stats.map((stat, i) => (
                    <StatisticsValue stat={stat} isLast={i + 1 === stats.length} key={stat.name} />
                ))}
                <Stack direction="column" justifyContent="center" alignItems="center" sx={{ height: 64 }}>
                    <Tooltip title="Copy all stats" arrow>
                        <span>
                            <IconButton onClick={() => copyStats(stats)} disabled={trades.length <= 0}>
                                <ContentCopySharpIcon sx={{ fontSize: 28 }} />
                            </IconButton>
                        </span>
                    </Tooltip>
                </Stack>
            </Toolbar>
        </OpenableAppBar>
    );
};
