import { Dispatch } from "react";
import { Sequence } from "../../../types";
import fuzzysort from "fuzzysort";
import { SearchState, SearchStateAction } from "./searchState";
import { searchOptionsLimit } from "./constants";

export const sequenceSearch = ({
    sequences,
    search,
    dispatch,
}: {
    sequences?: Sequence[];
    search: SearchState;
    dispatch: Dispatch<SearchStateAction>;
}) => {
    const {
        input: { sequence: sequenceInput },
    } = search;
    const allSequences = sequences || [];

    const findSequence = (_input: string): Sequence | null => allSequences.find(
        (sq) =>
            sq.displayName.toLowerCase() === _input.toLowerCase() ||
            (/^\d+$/.test(_input) && parseInt(_input) === sq.id)
    ) || null;

    const handleSequenceInputChanged = (_sequenceInput: string) => {
        const exactMatch = findSequence(_sequenceInput);
        if (exactMatch) {
            dispatch({ type: "setSequence", sequence: exactMatch });
        } else {
            dispatch({ type: "setSequenceInput", input: _sequenceInput });
        }
    };

    const handleSequenceInputBlur = (_sequenceInput: string) => {
        const exactMatch = findSequence(_sequenceInput);
        if (exactMatch) {
            dispatch({ type: "setSequence", sequence: exactMatch });
        } else {
            dispatch({ type: "setSequenceInvalid", input: _sequenceInput });
        }
    };

    const sequenceSearchOptions = (() => {
        const searchInput = sequenceInput.toLowerCase();

        if (searchInput === "") {
            return allSequences.slice(0, searchOptionsLimit).map((sq) => sq.displayName);
        }

        const sorted = fuzzysort.go(searchInput, allSequences, {
            keys: ["displayName"],
            limit: searchOptionsLimit,
            all: true,
            threshold: -1_000_000,
            scoreFn: (a) => {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                const sq: Sequence = (a as any).obj;
                if (/^\d+$/.test(searchInput) && parseInt(searchInput) === sq.id) {
                    return 900_000;
                }
                const [nameMatch] = a;
                if (nameMatch === null) {
                    return -1_000_001;
                }
                return nameMatch.score;
            },
        });
        return sorted.map((result) => result.obj).map((sq) => sq.displayName);
    })();

    return {
        handleSequenceInputBlur,
        handleSequenceInputChanged,
        sequenceSearchOptions,
    };
};
