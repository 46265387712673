import { useMemo } from "react";
import { GridColDef } from "@mui/x-data-grid-pro";
import { Trade } from "../../types";
import { Moment } from "moment";
import { customDateTimeFilters, customBuySellFilters } from "./customFilters";

interface IsBuyBoxProps {
    isBuy: boolean;
}

const IsBuyBox: React.FC<IsBuyBoxProps> = ({ isBuy }) => {
    return (
        <div
            style={{
                backgroundColor: isBuy ? "green" : "red",
                width: 18,
                height: 18,
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <b>{isBuy ? "B" : "S"}</b>
        </div>
    );
};

export interface TradeColDef extends GridColDef {
    field: keyof Trade;
    hideByDefault?: boolean;
    csvFormatter?: (value: unknown) => string;
}

const toCsvString = (value: string) => {
    const escaped = value.replace(`"`, `\\"`);
    return `"${escaped}"`
}

export const useColumnDefinitions = (trades: Trade[]): TradeColDef[] => {
    const venues = useMemo(() => {
        return Array.from(new Set(trades.map((trade) => trade.venueCode)));
    }, [trades]);
    const routes = useMemo(() => {
        return Array.from(new Set(trades.map((trade) => trade.route)));
    }, [trades]);
    const routeIds = useMemo(() => {
        return Array.from(new Set(trades.map((trade) => trade.routeId)));
    }, [trades]);
    return [
        {
            field: "tradeId",
            headerName: "Trade Id",
            minWidth: 170,
            hideable: false,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "dealDate",
            headerName: "Date/Time",
            minWidth: 180,
            type: "date",
            valueFormatter: ({ value }) => (value ? (value as Moment).format("DD/MM/YYYY HH:mm:ss") : ""),
            filterOperators: customDateTimeFilters,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "venueCode",
            headerName: "Code",
            minWidth: 120,
            type: "singleSelect",
            valueOptions: venues,
            csvFormatter: (value) => (value !== undefined ? toCsvString(value as string) : ""),
            align: "left",
            headerAlign: "left",
        },
        {
            field: "price",
            headerName: "Price",
            type: "number",
            csvFormatter: (value) => (value !== undefined ? (value as number).toString() : ""),
            minWidth: 120,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "quantity",
            headerName: "Quantity",
            type: "number",
            csvFormatter: (value) => (value !== undefined ? (value as number).toString() : ""),
            minWidth: 120,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorBuy",
            headerName: "Buy/Sell",
            minWidth: 130,
            type: "boolean",
            renderCell: (params) => <IsBuyBox isBuy={!!params.value} />,
            csvFormatter: (value) => (value ? "Buy" : "Sell"),
            filterOperators: customBuySellFilters,
            align: "left",
            headerAlign: "left",
            description: "Aggressor Action"
        },
        {
            field: "aggressorOwnedSpread",
            headerName: "Aggressor Owned spread",
            type: "boolean",
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "fromBrokenSpread",
            headerName: "From Broken Spread",
            type: "boolean",
            minWidth: 220,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorOwnedSpread",
            headerName: "Initiator Owned Spread",
            type: "boolean",
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorSleeve",
            headerName: "Initiator Sleeve",
            type: "boolean",
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorSleeve",
            headerName: "Aggressor Sleeve",
            type: "boolean",
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "route",
            headerName: "Route",
            minWidth: 150,
            type: "singleSelect",
            valueOptions: routes,
            hideByDefault: false,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "routeId",
            headerName: "Route Id",
            minWidth: 150,
            type: "singleSelect",
            valueOptions: routeIds,
            hideByDefault: true,
            align: "right",
            headerAlign: "left",
        },
        {
            field: "isPrivateTrade",
            headerName: "Is Own Trade",
            type: "boolean",
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorCompanyId",
            headerName: "Aggressor Company Id",
            type: "number",
            minWidth: 220,
            hideByDefault: true,
            csvFormatter: (value) => (value !== undefined ? (value as number).toString() : ""),
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorTraderCompanyName",
            headerName: "Aggressor Trader Company Name",
            type: "string",
            csvFormatter: (value) => (value !== undefined ? toCsvString(value as string) : ""),
            minWidth: 300,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorTraderId",
            headerName: "Aggressor Trader Id",
            type: "number",
            minWidth: 220,
            hideByDefault: true,
            csvFormatter: (value) => (value !== undefined ? (value as number).toString() : ""),
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorTraderName",
            headerName: "Aggressor Trader Name",
            type: "string",
            csvFormatter: (value) => (value !== undefined ? toCsvString(value as string) : ""),
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "aggressorDerivativeIndicator",
            headerName: "Aggressor Derivative Indicator",
            type: "boolean",
            minWidth: 300,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorCompanyId",
            headerName: "Initiator Company Id",
            type: "number",
            minWidth: 220,
            hideByDefault: true,
            csvFormatter: (value) => (value !== undefined ? (value as number).toString() : ""),
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorTraderCompanyName",
            headerName: "Initiator Trader Company Name",
            type: "string",
            csvFormatter: (value) => (value !== undefined ? toCsvString(value as string) : ""),
            minWidth: 300,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorTraderId",
            headerName: "Initiator Trader Id",
            type: "number",
            minWidth: 220,
            hideByDefault: true,
            csvFormatter: (value) => (value !== undefined ? (value as number).toString() : ""),
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorTraderName",
            headerName: "Initiator Trader Name",
            type: "string",
            csvFormatter: (value) => (value !== undefined ? toCsvString(value as string) : ""),
            minWidth: 250,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "initiatorDerivativeIndicator",
            headerName: "Initiator Derivative Indicator",
            type: "boolean",
            minWidth: 300,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
        {
            field: "productClassification",
            headerName: "Product Classification",
            type: "string",
            csvFormatter: (value) => (value !== undefined ? toCsvString(value as string) : ""),
            minWidth: 220,
            hideByDefault: true,
            align: "left",
            headerAlign: "left",
        },
    ];
};
