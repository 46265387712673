import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { TypographyProps } from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { ClickableTypography } from "../Typography";
import { Stat } from "./types";
import { copyStats } from "./utils";
import { putToClipboard } from "../../clipboard";

interface StatisticsValueProps {
    stat: Stat;
    isLast?: boolean;
}

const StatField: React.FC<TypographyProps> = ({ children, ...props }) => {
    return <ClickableTypography {...props}>{children}</ClickableTypography>;
};

export const StatisticsValue: React.FC<StatisticsValueProps> = ({ stat, isLast = false }) => {
    const [highlight, setHighlight] = useState("unset");
    const theme = useTheme();

    return (
        <Stack sx={{ flexGrow: isLast ? 1 : 2, alignItems: "flex-end", whiteSpace: "break-spaces" }}>
            <StatField
                onClick={() => copyStats([stat])}
                onMouseOver={() => setHighlight(theme.palette.trayport.table.hoverUnselectedTrades)}
                onMouseLeave={() => setHighlight("unset")}
                onMouseDown={() => setHighlight(theme.palette.trayport.hoverSearchElements)}
                onMouseUp={() => setHighlight(theme.palette.trayport.table.hoverUnselectedTrades)}
            >
                {stat.name}
                <small style={{ fontSize: "0.5rem" }}>
                    <b>
                        { stat.unit !== undefined ? ` (${stat.unit})` : ""}
                    </b>
                </small>
            </StatField>
            <StatField sx={{ bgcolor: highlight }} onClick={() => putToClipboard(stat.value?? "")}>
                <b>{stat.value === undefined ? "\n" : stat.value}</b>
            </StatField>
            <StatField
                sx={{ bgcolor: highlight }}
                onClick={() => putToClipboard(stat.selectedValue ?? "")}
            >
                <b>{stat.selectedValue === undefined ? "\n" : stat.selectedValue}</b>
            </StatField>
        </Stack>
    );
};
