import { Reducer } from "react";
import { SequenceItemInput, defaultSequenceItemInput } from "../../SequenceItemSelector";
import { ContractType, Instrument, Market, Sequence, SequenceItemWithSequence } from "../../../types";
import { SearchError } from "./types";
import { instrumentNameWithMarker, formatSequenceItemWithSequenceName, expectUnreachable } from "./utils";

const defaultSearchError: SearchError = {
    instrument: null,
    sequence: null,
    sequenceItem: null,
    secondSequenceItem: null,
};

export interface SearchState {
    selected: {
        instMarket: Instrument | Market | null;
        sequence: Sequence | null;
        sequenceItem: SequenceItemWithSequence | null;
        secondSequenceItem: SequenceItemWithSequence | null;
    };
    input: {
        instrument: string;
        sequence: string;
        sequenceItem: SequenceItemInput;
    };
    error: SearchError;
    flags: {
        allowSwap: boolean;
        isValid: boolean;
    };
}

export const defaultSearchState: SearchState = {
    selected: {
        instMarket: null,
        sequence: null,
        sequenceItem: null,
        secondSequenceItem: null,
    },
    input: {
        instrument: "",
        sequence: "",
        sequenceItem: defaultSequenceItemInput,
    },
    error: defaultSearchError,
    flags: {
        allowSwap: false,
        isValid: false,
    },
};

export type SearchStateAction =
    | { type: "setInstrument"; instMarket: Instrument | Market | null }
    | { type: "setInstrumentInput"; input: string }
    | { type: "setInstrumentInvalid"; input: string; error?: string }
    | { type: "setSequence"; sequence: Sequence | null }
    | { type: "setSequenceInput"; input: string }
    | { type: "setSequenceInvalid"; input: string; error?: string }
    | { type: "clearSequenceItems" }
    | { type: "setSequenceItem"; sequenceItem: SequenceItemWithSequence | null }
    | { type: "setSequenceItemInput"; input: string }
    | { type: "setSequenceItemInvalid"; input: string; error?: string }
    | { type: "clearSequenceItem" }
    | { type: "setSecondSequenceItem"; sequenceItem: SequenceItemWithSequence | null }
    | { type: "setSecondSequenceItemInput"; input: string }
    | { type: "setSecondSequenceItemInvalid"; input: string; error?: string }
    | { type: "clearSecondSequenceItem" }
    | { type: "setContractType"; contractType: ContractType }
    | { type: "compound"; actions: SearchStateAction[] };

export const instrumentSearchReducer: Reducer<SearchState, SearchStateAction> = (previousState, action) => {
    const validateState = (state: SearchState): SearchState => {
        let validState = state;

        const sequencesMatch =
            state.selected.secondSequenceItem &&
            state.selected.sequenceItem &&
            state.selected.sequenceItem.sequence.id === state.selected.secondSequenceItem.sequence.id;

        // Reset flags
        validState = {
            ...validState,
            flags: {
                ...validState.flags,
                allowSwap: false,
            },
        };

        if (sequencesMatch && state.selected.secondSequenceItem?.id === state.selected.sequenceItem?.id) {
            validState = {
                ...validState,
                error: {
                    ...validState.error,
                    secondSequenceItem: "Second period shouldn't match the first period",
                },
            };
        }
        if (sequencesMatch && (state.selected.secondSequenceItem?.id ?? 0) < (state.selected.sequenceItem?.id ?? 0)) {
            validState = {
                ...validState,
                error: {
                    ...validState.error,
                    secondSequenceItem: "Second period must be after the first",
                },
                flags: {
                    ...validState.flags,
                    allowSwap: true,
                },
            };
        }

        validState = {
            ...validState,
            flags: {
                ...validState.flags,
                isValid:
                    !!validState.selected.instMarket &&
                    !!validState.selected.sequence &&
                    !!validState.selected.sequenceItem &&
                    (validState.input.sequenceItem?.contractType === "SinglePeriod" ||
                        !!validState.selected.secondSequenceItem),
            },
        };

        return validState;
    };

    const handleAction = (_previousState: SearchState, _action: SearchStateAction): SearchState => {
        switch (_action.type) {
            case "setInstrument":
                return {
                    ..._previousState,
                    selected: {
                        ..._previousState.selected,
                        instMarket: _action.instMarket,
                    },
                    input: {
                        ..._previousState.input,
                        instrument: _action.instMarket ? instrumentNameWithMarker(_action.instMarket) : "",
                    },
                    error: {
                        ..._previousState.error,
                        instrument: null,
                    },
                };
            case "setInstrumentInput":
                return {
                    ..._previousState,
                    input: {
                        ..._previousState.input,
                        instrument: _action.input,
                    },
                };
            case "setInstrumentInvalid":
                return handleAction(
                    {
                        ..._previousState,
                        selected: {
                            ..._previousState.selected,
                            instMarket: null,
                        },
                        error: {
                            ..._previousState.error,
                            instrument: _action.error ?? "Invalid product",
                        },
                    },
                    { type: "setInstrumentInput", input: _action.input }
                );
            case "setSequence": {
                let state = _previousState;
                // Clear items if sequences don't match
                if (!!_action.sequence && _previousState.selected.sequenceItem?.sequence.id !== _action.sequence.id) {
                    state = handleAction(state, { type: "clearSequenceItem" });
                }
                if (
                    !!_action.sequence &&
                    _previousState.selected.secondSequenceItem?.sequence?.id !== _action.sequence.id
                ) {
                    state = handleAction(state, { type: "clearSecondSequenceItem" });
                }

                return {
                    ...state,
                    selected: {
                        ...state.selected,
                        sequence: _action.sequence,
                    },
                    input: {
                        ...state.input,
                        sequence: _action.sequence?.displayName || "",
                    },
                    error: {
                        ...state.error,
                        sequence: null,
                    },
                };
            }
            case "setSequenceInput":
                return {
                    ..._previousState,
                    input: {
                        ..._previousState.input,
                        sequence: _action.input,
                    },
                };
            case "setSequenceInvalid":
                return handleAction(
                    {
                        ..._previousState,
                        selected: {
                            ..._previousState.selected,
                            sequence: null,
                        },

                        error: {
                            ..._previousState.error,
                            sequence: _action.error ?? "Invalid calendar",
                        },
                    },
                    { type: "setSequenceInput", input: _action.input }
                );
            case "clearSequenceItems":
                return {
                    ..._previousState,
                    selected: {
                        ..._previousState.selected,
                        sequenceItem: null,
                        secondSequenceItem: null,
                    },
                    input: {
                        ..._previousState.input,
                        sequenceItem:
                            _previousState.input.sequenceItem.contractType === "SinglePeriod"
                                ? {
                                      ..._previousState.input.sequenceItem,
                                      sequenceItemId: "",
                                  }
                                : {
                                      ..._previousState.input.sequenceItem,
                                      sequenceItemId: "",
                                      secondSequenceItemId: "",
                                  },
                    },
                    error: {
                        ..._previousState.error,
                        sequenceItem: null,
                        secondSequenceItem: null,
                    },
                };
            case "setSequenceItem": {
                let state = _previousState;
                if (_action.sequenceItem) {
                    state = handleAction(state, {
                        type: "setSequence",
                        sequence: _action.sequenceItem?.sequence ?? null,
                    });
                }

                return {
                    ...state,
                    selected: {
                        ...state.selected,
                        sequenceItem: _action.sequenceItem,
                    },
                    input: {
                        ...state.input,
                        sequenceItem: {
                            ...state.input.sequenceItem,
                            sequenceItemId: _action.sequenceItem
                                ? formatSequenceItemWithSequenceName(_action.sequenceItem)
                                : "",
                        },
                    },
                    error: {
                        ...state.error,
                        sequenceItem: null,
                        secondSequenceItem: null,
                    },
                };
            }
            case "setSequenceItemInput":
                return {
                    ..._previousState,
                    input: {
                        ..._previousState.input,
                        sequenceItem: {
                            ..._previousState.input.sequenceItem,
                            sequenceItemId: _action.input,
                        },
                    },
                };
            case "setSequenceItemInvalid":
                return handleAction(
                    {
                        ..._previousState,
                        selected: {
                            ..._previousState.selected,
                            sequenceItem: null,
                        },
                        error: {
                            ..._previousState.error,
                            sequenceItem: _action.error ?? "Invalid period",
                        },
                    },
                    { type: "setSequenceItemInput", input: _action.input }
                );
            case "clearSequenceItem":
                return {
                    ..._previousState,
                    selected: {
                        ..._previousState.selected,
                        sequenceItem: null,
                    },
                    input: {
                        ..._previousState.input,
                        sequenceItem: {
                            ..._previousState.input.sequenceItem,
                            sequenceItemId: "",
                        },
                    },
                    error: {
                        ..._previousState.error,
                        sequenceItem: null,
                    },
                };
            case "setSecondSequenceItem": {
                let state = _previousState;
                if (_action.sequenceItem) {
                    state = handleAction(state, {
                        type: "setSequence",
                        sequence: _action.sequenceItem?.sequence ?? null,
                    });
                }

                return {
                    ...state,
                    selected: {
                        ...state.selected,
                        secondSequenceItem: _action.sequenceItem,
                    },
                    input: {
                        ...state.input,
                        sequenceItem:
                            state.input.sequenceItem.contractType === "SinglePeriod"
                                ? state.input.sequenceItem
                                : {
                                      ...state.input.sequenceItem,
                                      secondSequenceItemId: _action.sequenceItem
                                          ? formatSequenceItemWithSequenceName(_action.sequenceItem)
                                          : "",
                                  },
                    },
                    error: {
                        ...state.error,
                        secondSequenceItem: null,
                    },
                };
            }
            case "setSecondSequenceItemInput":
                return {
                    ..._previousState,
                    input: {
                        ..._previousState.input,
                        sequenceItem:
                            _previousState.input.sequenceItem.contractType === "SinglePeriod"
                                ? _previousState.input.sequenceItem
                                : {
                                      ..._previousState.input.sequenceItem,
                                      secondSequenceItemId: _action.input,
                                  },
                    },
                };
            case "setSecondSequenceItemInvalid":
                return handleAction(
                    {
                        ..._previousState,
                        selected: {
                            ..._previousState.selected,
                            secondSequenceItem: null,
                        },
                        error: {
                            ..._previousState.error,
                            secondSequenceItem: _action.error ?? "Invalid second period",
                        },
                    },
                    { type: "setSecondSequenceItemInput", input: _action.input }
                );
            case "clearSecondSequenceItem":
                return {
                    ..._previousState,
                    selected: {
                        ..._previousState.selected,
                        secondSequenceItem: null,
                    },
                    input: {
                        ..._previousState.input,
                        sequenceItem:
                            _previousState.input.sequenceItem.contractType === "SinglePeriod"
                                ? _previousState.input.sequenceItem
                                : {
                                      ..._previousState.input.sequenceItem,
                                      secondSequenceItemId: "",
                                  },
                    },
                    error: {
                        ..._previousState.error,
                        secondSequenceItem: null,
                    },
                };
            case "setContractType":
                return {
                    ..._previousState,
                    selected: {
                        ..._previousState.selected,
                        secondSequenceItem:
                            _action.contractType === "SinglePeriod" ? null : _previousState.selected.secondSequenceItem,
                    },
                    input: {
                        ..._previousState.input,
                        sequenceItem:
                            _action.contractType === "SinglePeriod"
                                ? {
                                      contractType: _action.contractType,
                                      sequenceItemId: _previousState.input.sequenceItem.sequenceItemId,
                                  }
                                : {
                                      contractType: _action.contractType,
                                      sequenceItemId: _previousState.input.sequenceItem.sequenceItemId,
                                      secondSequenceItemId:
                                          _previousState.input.sequenceItem.contractType === "SinglePeriod"
                                              ? ""
                                              : _previousState.input.sequenceItem.secondSequenceItemId,
                                  },
                    },
                };
            case "compound":
                return _action.actions.reduce(handleAction, _previousState);
            default:
                expectUnreachable(_action);
        }
        return _previousState;
    };

    return validateState(handleAction(previousState, action));
};
