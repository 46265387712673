import { Instrument, Market, SequenceItemWithSequence } from "../../../types";

const marketMarker = " (All Venues)";

export const stripMarketMarker = (input: string) => {
    if (input.endsWith(marketMarker)) {
        return [input.slice(0, input.length - marketMarker.length), true] as const;
    } else {
        return [input, false] as const;
    }
};

export const instrumentNameWithMarker = (instOrMarket: Instrument | Market) =>
    `${instOrMarket.name}${instOrMarket.type === "market" ? marketMarker : ""}`;

export const formatSequenceItemWithSequenceName = (sqi: SequenceItemWithSequence) =>
    `${sqi.name} [${sqi.sequence.displayName}]`;

export const splitSequenceItemName = (input: string) => {
    const match = input.match(/^([^\[]*)\s\[([^\]]*)\]$/); // eslint-disable-line no-useless-escape
    return [match ? match[1] : input, match ? match[2] : null] as const;
};

export const expectUnreachable = (action: never) => {       // This is used for typescript checking that some code path is expected to be unreachable, because a type has been exhaused
    throw new Error(`This action was not supposed to be reached ${action}`);
};
