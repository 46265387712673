import React from "react";
import { DateTimeRangePicked, dateTimePresets, DateTimePresetKeys } from "../DateRangePicker";
import { InstrumentResult } from "./instrumentSearch";
import { SearchParameters } from "../../types";
import { SelectValue, ValueSelect } from "../Select";
import moment, { Moment } from "moment";

interface SearchHistorySelectProps {
    searchHistory?: SearchParameters[];
    onApplyHistory?: (parameters: SearchParameters, dateRange: DateTimeRangePicked) => void;
    currentTime: DateTimeRangePicked;
    currentInstrument: InstrumentResult;
}

export const SearchHistorySelect: React.FC<SearchHistorySelectProps> = ({
    searchHistory = [],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    onApplyHistory = (params: SearchParameters, dateRange: DateTimeRangePicked) => null,
    currentInstrument,
    currentTime,
}) => {
    if (searchHistory.length <= 0) {
        return null;
    }

    const invertedSearchHistory = [...searchHistory].reverse().map((params, i) => {
        const now = moment();
        const timePresetMatch = Object.entries(dateTimePresets).find(
            (entry) => entry[1](now).from?.isSame(params.from) && entry[1](now).until?.isSame(params.until)
        );

        return {
            params,
            i,
            timePreset: {
                preset: timePresetMatch ? (timePresetMatch[0] as DateTimePresetKeys) : "Custom",
                range: { from: params.from, until: params.until },
            } as DateTimeRangePicked,
        };
    });

    const formatDate = (dt: Moment) => {
        if (dt.hours() == 0 && dt.minutes() == 0 && dt.seconds() == 0) {
            return dt.format("YYYY/MM/DD");
        }
        return dt.format("YYYY/MM/DD HH:mm");
    };

    const searchValues = invertedSearchHistory.map<SelectValue>(({ params, i, timePreset }) => {
        const timeDisplay =
            timePreset.preset !== "Custom"
                ? timePreset.preset
                : `${formatDate(params.from)} - ${formatDate(params.until)}`;

        return {
            value: i,
            label:
                `${params.instrument.name}${params.instrument.type == "market" ? "*" : ""} ` +
                `${params.sequenceItem.name}` +
                `${params.secondSequenceItem ? "-" + params.secondSequenceItem.name : ""} ` +
                `[${params.sequence.name}]: ${timeDisplay}`,
        };
    });

    const handleApply = (i: number) => {
        if (i === -1) {
            return;
        }
        const { params, timePreset } = invertedSearchHistory[i];

        onApplyHistory(params, timePreset);
    };

    const current = invertedSearchHistory.find(({ params }) => {
        return (
            params.instrument.id === currentInstrument?.instrument?.id &&
            params.sequence.id === currentInstrument?.sequence?.id &&
            params.sequenceItem.id === currentInstrument?.sequenceItem?.id &&
            (params.secondSequenceItem?.id ?? null) === (currentInstrument?.secondSequenceItem?.id ?? null) &&
            params.contractType === currentInstrument?.contractType &&
            params.from.isSame(currentTime.range.from) &&
            params.until.isSame(currentTime.range.until)
        );
    });

    return (
        <ValueSelect
            selectValues={searchValues}
            value={current?.i ?? ""}
            onValueChange={(v) => handleApply(v.value as number)}
            label="Previous searches"
        />
    );
};
