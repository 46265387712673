import React, { createContext, useContext } from "react";

export interface ApiConfiguration {
    tradesApiEndpoint: string;
    refDataApiEndpoint: string;
    apiKey?: string;
}

export interface AppConfiguration {
    apiConfiguration: ApiConfiguration;
}

export const defaultApiConfig: ApiConfiguration = {
    refDataApiEndpoint: process.env.REACT_APP_REFDATA_ENDPOINT ?? "https://referencedata.trayport.com",
    tradesApiEndpoint:
        process.env.REACT_APP_TRADES_ENDPOINT ?? "https://analytics.trayport.com",
    apiKey: process.env.REACT_APP_API_KEY,
};

export const defaultAppConfig: AppConfiguration = {
    apiConfiguration: defaultApiConfig,
};

export const useApiConfig = (): ApiConfiguration => {
    const apiConfContext = useContext(configurationContext);

    if (apiConfContext === null) {
        throw new Error("useApiConfig must be used within AppConfigurationContext");
    }

    return apiConfContext.config.apiConfiguration;
};

export const useAppConfig = (): AppConfiguration => {
    const apiConfContext = useContext(configurationContext);

    if (apiConfContext === null) {
        throw new Error("useAppConfig must be used within AppConfigurationContext");
    }

    return apiConfContext.config;
};

export interface ConfigurationContext {
    config: AppConfiguration;
    setConfig: React.Dispatch<React.SetStateAction<AppConfiguration>>;
}

export const configurationContext = createContext<ConfigurationContext | null>(null);
