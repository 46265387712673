import { ApiConfiguration } from "../config";
import {
    Instrument,
    Market,
    Sequence,
    InstrumentDetails,
    MarketDetails,
    TradingSpecifications,
} from "../../types";
import { USER_AGENT } from "../userAgent";
import moment from "moment";

interface RawInstrument {
    id: number;
    name: string;
    sequences: string;
}

interface RawMarket {
    id: number;
    name: string;
    sequences: string;
}

interface RawInstrumentDetails extends RawInstrument {
    tradingSpecifications: TradingSpecifications;
}

interface RawMarketDetails extends RawMarket {
    tradingSpecifications: TradingSpecifications;
}

interface RawSequenceItem {
    id: number;
    name: string;
    periodStart?: string;
    periodEnd?: string;
}

export interface RawSequence {
    id: number;
    name: string;
    displayName?: string;
    sequenceItems: string;
}

export default class RefDataApi {
    config: ApiConfiguration;

    constructor(config: ApiConfiguration) {
        this.config = config;
    }

    get<T>(query: string): () => Promise<T> {
        return async () => {
            const res = await fetch(`${this.config.refDataApiEndpoint}${query}`, {
                headers: {
                    ...(this.config.apiKey ? { "x-api-key": this.config.apiKey } : {}),
                    "User-Agent": USER_AGENT
                }
            });

            if (!res.ok) {
                throw new Error(res.statusText);
            }

            return await res.json();
        };
    }

    instruments(): () => Promise<Instrument[]> {
        return () =>
            this.get<RawInstrument[]>("/instruments")().then((res) =>
                res.map((inst) => ({ ...inst, type: "instrument" }))
            );
    }

    markets(): () => Promise<Market[]> {
        return () =>
            this.get<RawMarket[]>("/markets")().then((res) => res.map((inst) => ({ ...inst, type: "market" })));
    }

    instrumentSequences(instrument: Instrument): () => Promise<RawSequence[]> {
        return this.get(`/instruments/${instrument.id}/sequences`);
    }

    marketSequences(market: Market): () => Promise<Sequence[]> {
        return this.get(`/markets/${market.id}/sequences`);
    }

    sequences(instOrMarket: Instrument | Market | null): () => Promise<RawSequence[]> {
        if (instOrMarket === null) {
            return async () => [];
        }
        if (instOrMarket.type === "instrument") {
            return this.instrumentSequences(instOrMarket);
        } else {
            return this.marketSequences(instOrMarket);
        }
    }

    sequenceItems(sequence: Sequence, startDate = moment("2000-01-01"), count = 1000000000): () => Promise<RawSequenceItem[]> {
        const startDateString = startDate.format("YYYY-MM-DD[T]HH:mm:ss[Z]");
        return this.get(`/sequences/${sequence.id}/sequenceItems?StartDate=${startDateString}&Count=${count}`);
    }

    instrumentDetails(instrumentId: number): () => Promise<InstrumentDetails> {
        return () =>
            this.get<RawInstrumentDetails>(`/instruments/${instrumentId}`)().then((inst) => ({
                ...inst,
                type: "instrument",
            }));
    }

    marketDetails(marketId: number): () => Promise<MarketDetails> {
        return () =>
            this.get<RawMarketDetails>(`/markets/${marketId}`)().then((inst) => ({
                ...inst,
                type: "market",
            }));
    }

    details(instOrMarket: Instrument | Market | null): () => Promise<InstrumentDetails | MarketDetails | null> {
        if (instOrMarket === null) {
            return async () => null;
        }
        if (instOrMarket.type === "instrument") {
            return this.instrumentDetails(instOrMarket.id);
        } else {
            return this.marketDetails(instOrMarket.id);
        }
    }
}
