export const putToClipboard = (content: string) => {
    const textArea = document.createElement("textarea");
    textArea.value = content;

    // Avoid scrolling to bottom
    textArea.style.top = "0";
    textArea.style.left = "0";
    textArea.style.width = "0";
    textArea.style.position = "fixed";
    textArea.style.whiteSpace = "pre-line";

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();
    document.execCommand("copy");
    textArea.remove();
};
