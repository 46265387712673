import moment from "moment";
import { useQueries } from "react-query";
import { ApiConfiguration } from "../config";
import { SearchParameters, Trade, TradingSpecifications } from "../../types";
import { useEffect, useState } from "react";
import { queryClient } from "../reactQuery";
import { useRefData } from "../refdata";
import { RawTrade, TaskStatus, TradesParameters } from "./types";
import TradesApi from "./tradesApi";
import { dateRangeSplitter, formatMomentWithNoMillis } from "./utils";

export interface TradesFetcher {
    dispatchRequest: (request: SearchParameters) => void;
    cancel: () => void;
    tasks: TaskStatus[];
    currentQuery: SearchParameters | null;
    tradingSpecifications: TradingSpecifications | null;
    reachedLimit: boolean;
}

const intradayCacheTimeMs = 10 * 1000; // 10 seconds
const historicalCacheTimeMs = 60 * 60 * 1000; // 1 hour
const maxSearchRangeDays = 31;
export const maxFetchedTrades = parseInt(process.env.REACT_APP_FETCHED_TRADES_LIMIT ?? "") ?? 100_000;
export const useTradesFetcher = (config: ApiConfiguration): TradesFetcher => {
    const [tasks, setTasks] = useState<TradesParameters[]>([]);
    const [activeTaskId, setActiveTaskId] = useState<number | null>(null);
    const [searchParams, setSearchParams] = useState<SearchParameters | null>(null);
    const { useInstrumentTradingSpecifications } = useRefData(config);
    const instrumentTradingSpecificationsResult = useInstrumentTradingSpecifications(searchParams?.instrument ?? null);
    const api = new TradesApi(config);

    const taskToKey = (task: TradesParameters): (string | null)[] => [
        "trades",
        formatMomentWithNoMillis(task.from),
        formatMomentWithNoMillis(task.until),
        task.marketId,
        task.instrumentId,
        task.sequenceId,
        task.sequenceItemId,
        task.secondSequenceItemId,
        task.contractType,
        config.tradesApiEndpoint,
        config.apiKey ?? "",
    ];

    const responses = useQueries(
        tasks.map((task, i) => ({
            queryKey: taskToKey(task),
            queryFn: api.trades(task),
            enabled: activeTaskId !== null && i <= activeTaskId,
            cacheTime: moment().startOf("day").isBefore(task.until) ? intradayCacheTimeMs : historicalCacheTimeMs,
            onSettled: (response: RawTrade[] | undefined) => {
                setActiveTaskId((_activeTask) => {
                    if (_activeTask === i) {
                        return i + 1;
                    } else {
                        return _activeTask;
                    }
                });
                if (
                    (fetchedTradesCount ?? 0) + (response?.length ?? 0) > maxFetchedTrades &&
                    (activeTaskId ?? 0) < responses.length
                ) {
                    cancel();
                }
            },
        }))
    );

    useEffect(() => {
        if(activeTaskId === null){
            return;
        }
        // Make sure there is one update all tasks are disabled.
        // That way the cache is forced to refresh when it is reenabled
        if(activeTaskId < 0){
            setActiveTaskId(0);
            return;
        }
    }, [activeTaskId])

    const startTasks = (_tasks: TradesParameters[], _search: SearchParameters) => {
        setTasks(_tasks);
        setActiveTaskId(-1);
        setSearchParams(_search);
    };

    const cancel = () => {
        if (activeTaskId !== null && activeTaskId < responses.length) {
            const activeTask = tasks[activeTaskId];
            queryClient.cancelQueries(taskToKey(activeTask));
        }
        setActiveTaskId(null);
    };

    const dispatchRequest = (request: SearchParameters) => {
        startTasks(
            dateRangeSplitter(request.from, request.until, maxSearchRangeDays).map((ft) => ({
                from: ft[0],
                until: ft[1],
                marketId: request.instrument.type === "market" ? request.instrument.id.toString() : "",
                instrumentId: request.instrument.type === "instrument" ? request.instrument.id.toString() : "",
                sequenceId: request.sequence.id.toString(),
                sequenceItemId: request.sequenceItem.id.toString(),
                secondSequenceItemId: request.secondSequenceItem?.id?.toString() || "",
                contractType: request.contractType,
                includePrivate: true,
                routes: ["all"],
                optionalFields: [
                    "AggressorOwnedSpread",
                    "FromBrokenSpread",
                    "InitiatorOwnedSpread",
                    "InitiatorSleeve",
                    "AggressorSleeve",
                    "Route",
                    "RouteId"
                ],
            })),
            request
        );
    };

    const _tasks: TaskStatus[] = tasks
        .map((task, i) => ({ task, resp: responses[i], i }))
        .map((t) => ({
            status: t.resp.isError
                ? "error"
                : activeTaskId !== null && activeTaskId < t.i
                ? "notStarted"
                : t.resp.isLoading || t.resp.isRefetching
                ? "fetching"
                : t.resp.data !== undefined
                ? "finished"
                : activeTaskId === null
                ? "cancelled"
                : "unknown",
            taskInfo: t.task,
            data: t.resp.data
                ?.map(
                    (t) =>
                        ({
                            tradeId: t.tradeId,
                            venueCode: t.venueCode,
                            dealDate: moment(t.dealDate / 10 ** 6),
                            price: t.price,
                            quantity: t.quantity,
                            aggressorBuy: t.aggressorBuy,
                            aggressorOwnedSpread: t.aggressorOwnedSpread,
                            fromBrokenSpread: t.fromBrokenSpread,
                            initiatorOwnedSpread: t.initiatorOwnedSpread,
                            initiatorSleeve: t.initiatorSleeve,
                            aggressorSleeve: t.aggressorSleeve,
                            route: t.route,
                            routeId: t.routeId,
                            isPrivateTrade: !!t.privateFields,
                            ...t.privateFields,
                        } as Trade)
                )
                .reverse(),
            error: (!!t.resp.error && `${t.resp.error}`) || undefined,
        }));

    const fetchedTradesCount = _tasks.reduce((sum, task) => (sum = sum + (task.data?.length ?? 0)), 0);
    const reachedFetchedTradesLimit = fetchedTradesCount > maxFetchedTrades && (activeTaskId ?? 0) < responses.length;

    return {
        dispatchRequest,
        cancel,
        tasks: _tasks,
        currentQuery: searchParams,
        tradingSpecifications: instrumentTradingSpecificationsResult.tradingSpecifications,
        reachedLimit: reachedFetchedTradesLimit,
    };
};

export const useTradeQueryGenerator = (config: ApiConfiguration) => {
    const api = new TradesApi(config);

    const getTradeQuery = (params: TradesParameters) => api.tradesQueryString(params);

    return {
        getTradeQuery,
    };
};
