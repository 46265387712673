import React from "react";
import { ClickableTypography, Typography } from "../Typography";
import { TaskStatus } from "../../Hooks/tradesFetcher";
import Box from "@mui/material/Box";
import { Tooltip } from "../Tooltip";
import ContentCopySharpIcon from "@mui/icons-material/ContentCopySharp";

interface TaskStatusTextProps {
    task: TaskStatus;
    onClick?: () => void;
}

export const TaskStatusText: React.FC<TaskStatusTextProps> = ({ task, onClick = () => null }) => {
    const fromDate = task.taskInfo.from.format("YYYY-MM-DD");
    const statusText =
        task.status === "finished"
            ? `${task.data?.length ?? 0} trades received`
            : task.status === "fetching"
            ? "Fetching trades..."
            : task.status === "notStarted"
            ? "Pending"
            : task.status === "error"
            ? task.error ?? ""
            : task.status === "cancelled"
            ? "Cancelled"
            : "Unknown";
    return (
        <Box>
            <Tooltip
                arrow
                title={
                    <Box>
                        <Typography variant="body2">{statusText}</Typography>
                        <Typography variant="body2" sx={(theme) => ({ marginTop: theme.spacing(2) })}>
                            <ContentCopySharpIcon sx={{ fontSize: 12, marginRight: 0.5 }} />
                            Click to copy Data Analytics API request
                        </Typography>
                    </Box>
                }
            >
                <ClickableTypography
                    noWrap
                    variant="body2"
                    onClick={onClick}
                >{`${fromDate}: ${statusText}`}</ClickableTypography>
            </Tooltip>
        </Box>
    );
};
