import React from "react";
import ThemeProvider from "../Hooks/theme";
import { AppConfigurationContext } from "../Hooks/AppConfigurationContext";

interface RoutedContextsProps {
    children: React.ReactNode;
}

export const RoutedContexts: React.FC<RoutedContextsProps> = ({ children }) => {
    return (
        <ThemeProvider>
            <AppConfigurationContext>{children}</AppConfigurationContext>
        </ThemeProvider>
    );
};
