import { styled } from "@mui/material/styles";
import MuiToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MuiToggleButton from "@mui/material/ToggleButton";

export const ToggleButtonGroup = styled(MuiToggleButtonGroup)(() => ({}));

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
    borderRadius: "0px",
    paddingBottom: "0px",
    paddingTop: "0px",
    width: "100%",
    "&.Mui-selected, &.Mui-selected:hover": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.trayport.backgroundThemeColor,
        fontWeight: "bold",
    },
    ":hover":{
        backgroundColor: theme.palette.trayport.hoverSearchElements,
        color: theme.palette.trayport.backgroundThemeColor
    },
    backgroundColor: theme.palette.trayport.backgroundSearchElements,
    color: theme.palette.text.primary
}));
