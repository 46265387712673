import { Trade } from "../../types";
import { TradeColDef } from "./columnDefinitions";
import { GridRowId, GridRowTreeNodeConfig, GridStateColDef } from "@mui/x-data-grid-pro";

export interface TradeWithId extends Trade {
    id: string;
}

export const addIdsToTrades = (trades: Trade[]): TradeWithId[] =>
    trades.map((trade) => ({
        ...trade,
        id: `${trade.tradeId}:${trade.venueCode}:${trade.routeId}`,
    }));

export const tradesToCsv = (_trades: Trade[], _columns: TradeColDef[], withHeader = true, delimiter=",", newline="\n") => {
    const header = _columns.map((col) => col.headerName).join(delimiter);
    return [
        ...(withHeader ? [header] : []),
        ..._trades.map((trade) =>
            _columns
                .map((col) => {
                    try {
                        if (col.csvFormatter) {
                            return col.csvFormatter(trade[col.field]);
                        }
                    } catch {}      // eslint-disable-line no-empty
                    try {
                        if (col.valueFormatter) {
                            return col.valueFormatter({ field: col.field, value: trade[col.field], api: null });
                        }
                    } catch {}      // eslint-disable-line no-empty
                    try {
                        if (col.valueGetter) {
                            return col.valueGetter({
                                field: col.field,
                                value: trade[col.field],
                                api: null,
                                id: "",
                                tabIndex: 0,
                                row: undefined,
                                rowNode: null as unknown as GridRowTreeNodeConfig,
                                colDef: col as unknown as GridStateColDef<any, any, any>,   // eslint-disable-line @typescript-eslint/no-explicit-any
                                cellMode: "view",
                                hasFocus: false,
                                getValue: function (id: GridRowId, field: string) {     // eslint-disable-line @typescript-eslint/no-unused-vars
                                    throw new Error("Function not implemented.");
                                },
                            });
                        }
                    } catch {}      // eslint-disable-line no-empty
                    return trade[col.field]?.toString();
                })
                .join(delimiter)
        ),
    ].join(newline);
};

export const downloadCsv = (content: string, fileName: string) => {
    const encoded = encodeURI(`data:text/csv;charset=utf-8,${content}`);
    const link = document.createElement("a");
    link.setAttribute("href", encoded);
    link.setAttribute("download", fileName);
    document.body.appendChild(link);

    link.click();
};
