import React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router-dom";
import TradesDashboard from "./Dashboards/TradesDashboard";
import { RoutedContexts } from "./Components";
import { QueryClientProvider } from "react-query";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { queryClient } from "./Hooks/reactQuery";
import ErrorPage from "./Dashboards/ErrorPage";

function App() {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <Navigate to={"/trades"} />,
        },
        {
            path: "/trades",
            element: (
                <RoutedContexts>
                    <TradesDashboard />
                </RoutedContexts>
            ),
            errorElement: (
                <RoutedContexts>
                    <ErrorPage />
                </RoutedContexts>
            ),
        },
    ]);

    return (
        <div className="App">
            <QueryClientProvider client={queryClient}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <CssBaseline />
                    <RouterProvider router={router} />
                </LocalizationProvider>
            </QueryClientProvider>
        </div>
    );
}

export default App;
