import React, { useEffect, useMemo, useReducer } from "react";
import { SearchField } from "../../SearchField";
import { SequenceItemSelector } from "../../SequenceItemSelector";
import { useRefData } from "../../../Hooks/refdata";
import { Sequence, SequenceItemWithSequence } from "../../../types";
import { useApiConfig } from "../../../Hooks/config";
import { InstrumentResult, InstrumentSearchValues, InstrumentSearchProps } from "./types";
import { defaultSearchState, instrumentSearchReducer } from "./searchState";
import { instrumentSearch } from "./instrumentSearch";
import { sequenceItemSearch } from "./sequenceItemSearch";
import { sequenceSearch } from "./sequenceSearch";

export const useInstrumentSearch = (fromDate?: moment.Moment): InstrumentSearchValues => {
    const [search, dispatch] = useReducer(instrumentSearchReducer, defaultSearchState);
    const { error } = search;

    const apiConfig = useApiConfig();
    const { useInstrumentSequences, ...refData } = useRefData(apiConfig);
    const refDataInstrumentSequences = useInstrumentSequences(search.selected.instMarket, fromDate);

    // Instrument
    const { handleInstrumentInputChange, handleInstrumentInputBlur, instrumentSearchOptions } = useMemo(
        () => instrumentSearch({ search, dispatch, refData }),
        [refData.instruments, refData.markets, search.input.instrument]
    );

    // Sequence item
    const { handleSequenceItemBlur, handleSequenceItemChanged, sequenceItemSearchOptions, secondSequenceItemSearchOptions } = useMemo(
        () => sequenceItemSearch({sequenceItems: refDataInstrumentSequences.sequenceItems, search, dispatch}),
        [refDataInstrumentSequences.sequenceItems, search.input.sequenceItem, search.selected.sequence]
    )

    // Sequence
    const { handleSequenceInputBlur, handleSequenceInputChanged, sequenceSearchOptions } = useMemo(
        () => sequenceSearch({sequences: refDataInstrumentSequences.sequences, search, dispatch}),
        [refDataInstrumentSequences.sequences, search.input.sequence]
    )


    // Clear sequence on instrument change
    useEffect(() => {
        if (refDataInstrumentSequences.allLoading || !search.selected.instMarket) return;

        const sequenceNotKnown = (_sq: Sequence | null) =>
            _sq && !(refDataInstrumentSequences.sequences || []).some((sq) => sq.id === _sq.id);

        if (sequenceNotKnown(search.selected.sequence)) {
            dispatch({ type: "setSequence", sequence: null });
        }

        const sequenceItemNotKnown = (_sqi: SequenceItemWithSequence | null) =>
            _sqi &&
            !(refDataInstrumentSequences.sequenceItems || []).some(
                (sq) => sq.sequence.id === _sqi.sequence.id && sq.id === _sqi.id
            );

        if (
            sequenceItemNotKnown(search.selected.sequenceItem) ||
            sequenceItemNotKnown(search.selected.secondSequenceItem)
        ) {
            dispatch({
                type: "compound",
                actions: [{ type: "clearSequenceItem" }, { type: "clearSecondSequenceItem" }],
            });
        }
    }, [
        refDataInstrumentSequences.sequences,
        refDataInstrumentSequences.sequenceItems,
        refDataInstrumentSequences.allLoading,
        search.selected.instMarket,
        search.selected.sequence,
        search.selected.secondSequenceItem,
    ]);

    const setSearch = (parameters: InstrumentResult) => {
        dispatch({
            type: "compound",
            actions: [
                { type: "setInstrument", instMarket: parameters.instrument },
                { type: "setSequence", sequence: parameters.sequence },
                { type: "setContractType", contractType: parameters.contractType },
                {
                    type: "setSequenceItem",
                    sequenceItem:
                        parameters.sequenceItem && parameters.sequence
                            ? {
                                  ...parameters.sequenceItem,
                                  sequence: parameters.sequence,
                              }
                            : null,
                },
                {
                    type: "setSecondSequenceItem",
                    sequenceItem:
                        parameters.secondSequenceItem && parameters.sequence
                            ? {
                                  ...parameters.secondSequenceItem,
                                  sequence: parameters.sequence,
                              }
                            : null,
                },
            ],
        });
    };

    const handleReset = () => {
        setSearch({
            instrument: null,
            sequence: null,
            sequenceItem: null,
            secondSequenceItem: null,
            contractType: "SinglePeriod",
        });
    };

    return {
        props: {
            instrumentSearchProps: {
                value: search.input.instrument,
                searchOptions: instrumentSearchOptions,
                onInputChange: handleInstrumentInputChange,
                onBlur: handleInstrumentInputBlur,
                disabled: refData.isLoading || refData.isError,
                filterDisabled: true,
                error: !!error.instrument || refData.isError,
                helperText: error.instrument || (refData.isError ? `${refData.error}` : ""),
            },
            sequenceItemSelectorProps: {
                value: search.input.sequenceItem,
                onChange: handleSequenceItemChanged,
                onBlur: handleSequenceItemBlur,
                disabled: !search.selected.instMarket || refDataInstrumentSequences.allLoading,
                sequenceItemSearchOptions,
                secondSequenceItemSearchOptions,
                sequenceItemError: error.sequenceItem || "",
                secondSequenceItemError: error.secondSequenceItem || "",
                allowSwap: search.flags.allowSwap,
                topHelperText: refDataInstrumentSequences.sequences.some((sq) => sq.isEpexSequence) ? "To get more periods, change `From` date" : ""
            },
            sequenceSearchProps: {
                value: search.input.sequence,
                onInputChange: handleSequenceInputChanged,
                onBlur: handleSequenceInputBlur,
                searchOptions: sequenceSearchOptions,
                disabled: !search.selected.instMarket || refDataInstrumentSequences.allLoading,
                filterDisabled: true,
                error: !!error.sequence,
                helperText: error.sequence || "",
            },
        },
        reset: handleReset,
        isValid: search.flags.isValid,
        result: {
            instrument: search.selected.instMarket,
            sequence: search.selected.sequence,
            sequenceItem: search.selected.sequenceItem,
            secondSequenceItem:
                search.input.sequenceItem.contractType === "SinglePeriod" ? null : search.selected.secondSequenceItem,
            contractType: search.input.sequenceItem.contractType,
        },
        setSearch,
    };
};

export const InstrumentSearch: React.FC<InstrumentSearchProps> = ({
    instrumentSearchProps,
    sequenceItemSelectorProps,
    sequenceSearchProps,
}) => (
    <>
        <SearchField label="Product" {...instrumentSearchProps} />
        <SequenceItemSelector {...sequenceItemSelectorProps} />
        <SearchField label="Calendar" {...sequenceSearchProps} />
    </>
);
