import { putToClipboard } from "../../clipboard";
import { Stat } from "./types";

export const copyStats = (stats: Stat[]) => {
    let textToCopy = [stats.length > 1 ? "Stats" : "Stat", ...stats.map((stat) => stat.name)].join("\t") + "\n";
    if (stats[0].value !== undefined) {
        textToCopy += ["Total", ...stats.map((stat) => stat.value)].join("\t") + "\n";
    }
    if (stats[0].selectedValue !== undefined) {
        textToCopy += ["Selected", ...stats.map((stat) => stat.selectedValue)].join("\t") + "\n";
    }
    putToClipboard(textToCopy);
};
