import moment from "moment";
import { Sequence, SequenceItem, SequenceItemWithSequence } from "../../types";
import { RawSequence } from "./refDataApi";

const findDuplicates = (values: (string | undefined)[]) =>
    values.reduce(
        (agg, value) => {
            if (!value) {
                return agg;
            }

            if (agg.all.has(value)) {
                return {
                    ...agg,
                    duplicates: agg.duplicates.add(value),
                };
            } else {
                return {
                    ...agg,
                    all: agg.all.add(value),
                };
            }
        },
        {
            all: new Set<string>(),
            duplicates: new Set<string>(),
        }
    ).duplicates;

const PRIORITISED_SEQUENCE_DISPLAY_NAMES = ["Prompt", "All Days", "Weeks", "Months", "Quarters", "Seasons", "Years"];

export const fixSequences = (sqs: RawSequence[] | undefined): Sequence[] | undefined => {
    // Ensure that display name always has a unique value
    if (sqs === undefined) {
        return;
    }

    const duplicates = findDuplicates(sqs.map((sq) => sq.displayName));

    const priorityWithId = PRIORITISED_SEQUENCE_DISPLAY_NAMES.map((p, i) => ({ displayName: p, priority: i }));

    const patchedDisplayNames = sqs
        .map((sq) => ({
            ...sq,
            patchedDisplayName: !sq.displayName || duplicates.has(sq.displayName) ? sq.name : sq.displayName,
        }))
        .sort((a, b) => {
            const aPriority = priorityWithId.find((p) => p.displayName === a.displayName);
            const bPriority = priorityWithId.find((p) => p.displayName === b.displayName);

            if (aPriority || bPriority) {
                // Order first by predefined priority
                if (aPriority && bPriority) {
                    return aPriority.priority - bPriority.priority;
                } else if (aPriority) {
                    return -1;
                } else {
                    return 1;
                }
            } else if (a.displayName && b.displayName) {
                // If both have display names, order by id
                return a.id - b.id;
            } else if (a.displayName) {
                // Prioritise ones with display names
                return -1;
            } else if (b.displayName) {
                return 1;
            } else {
                // Sort ones without display name by id
                return a.id - b.id;
            }
        });

    return patchedDisplayNames.map((sq) => ({
        id: sq.id,
        name: sq.name,
        displayName: sq.patchedDisplayName,
        sequenceItems: sq.sequenceItems,
        isEpexSequence: false,
    }));
}; // TODO: test custom ordering

const sortById = (sqis: SequenceItem[]) => sqis.sort((a, b) => a.id - b.id);

export const fixSequenceItems = (sqis: SequenceItem[]): SequenceItem[] => {
    // Force Sequence item names to be unique. Assumes that all items are on the same sequence
    const duplicates = findDuplicates(sqis.map((sqi) => sqi.name));

    const result = sqis.filter((sqi) => !duplicates.has(sqi.name));

    if (duplicates.size === 0) {
        return sortById(result);
    }

    // Add id to items with missing period start
    result.push(
        ...sqis
            .filter((sqi) => duplicates.has(sqi.name))
            .filter((sqi) => !sqi.periodStart)
            .map((sqi) => ({
                id: sqi.id,
                name: `${sqi.name} [${sqi.id}]`,
                periodStart: sqi.periodStart,
                periodEnd: sqi.periodEnd,
            }))
    );

    // Try adding just year
    const sqisWithYear = sqis
        .filter((sqi) => duplicates.has(sqi.name))
        .filter((sqi) => !!sqi.periodStart)
        .map((sqi) => ({
            ...sqi,
            nameWithYear: `${sqi.name} ${sqi.periodStart?.format("YYYY")}`,
        }));

    const duplicatesWithYear = findDuplicates(sqisWithYear.map((sqi) => sqi.nameWithYear));

    result.push(
        ...sqisWithYear
            .filter((sqi) => !duplicatesWithYear.has(sqi.nameWithYear))
            .map((sqi) => ({
                id: sqi.id,
                name: sqi.nameWithYear,
                periodStart: sqi.periodStart,
                periodEnd: sqi.periodEnd,
            }))
    );

    if (duplicatesWithYear.size === 0) {
        return sortById(result);
    }

    // Try adding full date
    const sqisWithDate = sqisWithYear
        .filter((sqi) => duplicatesWithYear.has(sqi.nameWithYear))
        .map((sqi) => ({
            ...sqi,
            nameWithDate: `${sqi.name} ${sqi.periodStart?.format("DD/MM/YYYY")}`,
        }));

    const duplicatesWithDate = findDuplicates(sqisWithDate.map((sqi) => sqi.nameWithDate));

    result.push(
        ...sqisWithDate
            .filter((sqi) => !duplicatesWithDate.has(sqi.nameWithDate))
            .map((sqi) => ({
                id: sqi.id,
                name: sqi.nameWithDate,
                periodStart: sqi.periodStart,
                periodEnd: sqi.periodEnd,
            }))
    );

    if (duplicatesWithDate.size === 0) {
        return sortById(result);
    }

    // Fall back to adding id
    result.push(
        ...sqisWithDate
            .filter((sqi) => duplicatesWithDate.has(sqi.nameWithDate))
            .map((sqi) => ({
                id: sqi.id,
                name: `${sqi.name} [${sqi.id}]`,
                periodStart: sqi.periodStart,
                periodEnd: sqi.periodEnd,
            }))
    );

    return sortById(result);
};

export const sequenceItemDefaultSort = (
    sequenceItems: SequenceItemWithSequence[],
    sortedSequences: Sequence[]
): SequenceItemWithSequence[] => {
    const sequenceItemsBySequence = sequenceItems.reduce((sqs, sqi) => {
        const arr = sqs.get(sqi.sequence.id) ?? [];
        arr.push(sqi);

        sqs.set(sqi.sequence.id, arr);
        return sqs;
    }, new Map<number, SequenceItemWithSequence[]>());

    const now = moment();
    const categorised = Array.from(sequenceItemsBySequence.entries()).map(([id, sqis]) => ({
        sqId: id,
        sqis: [
            ...sqis
                .filter((sqi) => sqi.periodEnd?.isAfter(now))
                .sort((a, b) => a.periodEnd?.diff(b.periodEnd || now) ?? 0),
            ...sqis.filter((sqi) => !sqi.periodEnd).sort((a, b) => a.id - b.id),
            ...sqis
                .filter((sqi) => sqi.periodEnd?.isBefore(now))
                .sort((a, b) => a.periodEnd?.diff(b.periodEnd || now) ?? 0),
        ],
    }));

    const sorted = sortedSequences.map((sq) => categorised.find((c) => c.sqId == sq.id)).filter((s) => !!s);

    return [
        ...sorted.map((c) => c?.sqis?.slice(0, 2) ?? []).flat(),
        ...sorted.map((c) => c?.sqis?.slice(2) ?? []).flat(),
    ];
}; // TODO: add tests
